import ComponentBaralhoModel from 'models/componentInteraction/ComponentBaralho';
import { GenericService } from '../Generic.service';

export class ComponentBaralhoService extends GenericService {
    readonly URI: string = '/v1/componentBaralho';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public insert(idComponente: number, model: ComponentBaralhoModel) {
        return this.post('' + idComponente, model);
    }

    public update(idComponente: number, model: ComponentBaralhoModel) {
        return this.put('' + idComponente, model);
    }

    public deleteByCardBaralho(idCardBaralho: number) {
        return this.delete(idCardBaralho);
    }
}
