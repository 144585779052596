import ComponentReguaAvaliativaFeedbacks from 'models/componentInteraction/ComponentReguaAvaliativaFeedbacks';
import { GenericService } from '../Generic.service';

export class ComponentReguaAvaliativaFeedbackService extends GenericService {
    readonly URI: string = '/v1/componentReguaAvaliativaFeedback';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    async insertAndUpdate(model: ComponentReguaAvaliativaFeedbacks, codigoRegua: number) {
        return await this.post('' + codigoRegua, model);
    }

    async updateRegua(codigoFeedback: number) {
        return await this.delete(codigoFeedback);
    }
}
