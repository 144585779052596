import { jsonProperty, Serializable } from 'ts-serializable';
import ComponentReguaAvaliativaOptions from './ComponentReguaAvaliativaOptions';

class ComponentReguaAvaliativaQuestions extends Serializable {
    @jsonProperty(Number)
    id: number = -1;

    @jsonProperty(String)
    titulo: string = '';

    @jsonProperty(String)
    textAux: string = '';

    options: ComponentReguaAvaliativaOptions[] | null = [];
}

export default ComponentReguaAvaliativaQuestions;
