import { Divider, Input, InputNumber } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { ToastError, ToastSuccess } from 'components/Toaster';
import Colors from 'enums/Colors';
import ComponentBaralhoModel from 'models/componentInteraction/ComponentBaralho';
import React, { Ref, useEffect, useRef, useState } from 'react';
import { IoPause, IoPauseOutline, IoPlayOutline, IoStopOutline } from 'react-icons/io5';
import { MdAdd, MdDelete } from 'react-icons/md';
import { TfiReload } from 'react-icons/tfi';
import styled from 'styled-components';
import { FiEdit } from 'react-icons/fi';
import { ComponentBaralhoService } from 'core/http/service/componetInteraction/ComponentBaralho.service';
import { ComponentInteractionService } from 'core/http/service/componetInteraction/ComponentInteraction.service';
import ReactQuill from 'react-quill';
import editorConfig from 'pages/diarioBordo/components/textEditor/editorConfig';
import { TextDangerouslySetInnerHtml } from 'pages/recursos/recursos-conteudo/RecursosConteudoPage.styled';

const Styled = {
    Container: styled.div`
        display: flex;
        flex-direction: column;
    `,

    Row: styled.div`
        display: flex;
        gap: 2rem;
    `,

    Column: styled.div`
        display: flex;
        flex-direction: column;
        gap: 1rem;
    `,

    Form: styled.div`
        display: flex;
        position: relative;
        flex-direction: column;
        min-width: 417px;
        min-height: 356px;
        padding: 10px;
        border: 1px solid #e6e6e6;
        gap: 10px;
    `,

    Title: styled.h1`
        //styleName: Base/Subtitulo;
        font-family: 'Barlow';
        font-size: 18px;
        font-weight: 600;
        line-height: 25.2px;
        text-align: left;
        text-underline-position: 'from-font';
        text-decoration-skip-ink: none;
    `,

    SubTitle: styled.h2`
        //styleName: Base/Legenda 2;
        font-family: 'Barlow';
        font-size: 14px;
        font-weight: 600;
        line-height: 19.6px;
        text-align: left;
        text-underline-position: 'from-font';
        text-decoration-skip-ink: none;
    `,

    Button: styled.button`
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        width: 100%;
        height: 40px;
        background-color: transparent;
        border-radius: 8px;
        border: 1px solid ${Colors.Blue};

        //styleName: Base/Corpo;
        font-family: 'Barlow';
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        text-underline-position: 'from-font';
        text-decoration-skip-ink: none;
        color: ${Colors.Blue};
    `,

    Divider: styled.div`
        width: 1px;
        background-color: #e6e6e6;
    `,

    ImgPreview: styled.img`
        max-width: 100%;
        height: 193px;
        object-fit: fill;
    `,

    Card: {
        Container: styled.div`
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            min-width: 320px;
            max-width: 320px;
            padding: 16px 16px;
            border: 1px 0px 0px 0px;
            opacity: 0px;
            border: 1px solid #f0f0f0;
            perspective: 1000px;
            transform-style: preserve-3d;
            transition: transform 0.6s;

            &.flipped {
                transform: rotateY(180deg); /* Gira o cartão */
            }
        `,

        Between: styled.div`
            display: flex;
            align-items: center;
            justify-content: space-between;
        `,

        Title: styled.h1`
            font-family: 'Barlow';
            font-size: 18px;
            font-weight: 600;
            line-height: 25.2px;
        `,

        PlayAudio: styled.div`
            border: 1px solid ${Colors.Blue};
            width: 36px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            transition: all 0.3s;
            cursor: pointer;

            &:hover {
                box-shadow: 2px 2px 4px #797979;
            }
        `,

        Footer: styled.div`
            display: flex;
            align-items: center;
            justify-content: end;
        `,

        Button: styled.button`
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            border-radius: 37px;
            opacity: 0px;
            background-color: ${Colors.Blue};
            color: white;
        `,

        Side: styled.div`
            position: absolute;
            width: 100%;
            height: 100%;
            backface-visibility: hidden; /* Oculta a parte de trás quando não está virada */
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
        `,

        Front: styled.div`
            display: flex;
            height: 100%;
            max-height: 380px;
            max-width: 390px;
            justify-content: space-between;
            flex-direction: column;
            overflow: scroll;
            overflow-x: hidden;
            overflow-y: auto;
            gap: 10px;
        `,

        Back: styled.div`
            position: absolute;
            display: flex;
            max-width: 390px;
            height: 100%;
            justify-content: space-between;
            flex-direction: column;
            backface-visibility: hidden;
            gap: 10px;
            backface-visibility: hidden;
            transform: rotateY(180deg); /* Gira o verso para que não apareça inicialmente */
        `,

        ButtonEdit: styled.div`
            display: flex;
            align-items: center;
            justify-content: center;
            top: -10px;
            left: 80%;
            background-color: ${Colors.Yellow2};
            width: 32px;
            height: 32px;
            border-radius: 10px;
            position: absolute;
            cursor: pointer;
        `,

        ButtonDelete: styled.div`
            display: flex;
            align-items: center;
            justify-content: center;
            top: -10px;
            left: 90%;
            background-color: ${Colors.DeleteRed};
            color: ${Colors.White};
            width: 32px;
            height: 32px;
            border-radius: 10px;
            position: absolute;
            cursor: pointer;
        `,

        Controls: styled.div`
            display: flex;
            gap: 10px; // Espaço entre os ícones
            margin-top: 10px; // Margem acima dos controles
            cursor: pointer; // Cursor de ponteiro ao passar o mouse
        `,
    },
};

interface ICardBaralho {
    card: ComponentBaralhoModel;
    setEdit: () => void;
    handleClickDelete: () => void;
    loadingDelete: boolean;
}

const CardBaralho = ({ card, setEdit, handleClickDelete, loadingDelete }: ICardBaralho) => {
    const [flipped, setFlipped] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);

    const audioRef = useRef<any>(null);
    const audio2Ref = useRef<any>(null);

    const handlePauseAudio = (ref: any) => {
        if (ref.current) {
            ref.current.pause();
            setIsPlaying(false);
        }
    };

    const handleFlip = () => {
        setFlipped(!flipped);
        if (audioRef.current) {
            audioRef.current.pause();
            audio2Ref.current.pause();
            audioRef.current.currentTime = 0;
            audio2Ref.current.currentTime = 0;
        }
    };

    const handlePlayAudio = (ref: any) => {
        if (ref.current) {
            ref.current.play();
            setIsPlaying(true);
        }
    };

    return (
        <Styled.Card.Container className={flipped ? 'flipped' : ''}>
            {!flipped && (
                <>
                    <Styled.Card.ButtonEdit onClick={() => setEdit()}>
                        <FiEdit />
                    </Styled.Card.ButtonEdit>
                    <Styled.Card.ButtonDelete onClick={handleClickDelete}>
                        {loadingDelete ? <div className="loaderDelete" /> : <MdDelete />}
                    </Styled.Card.ButtonDelete>
                </>
            )}

            <Styled.Card.Front style={{ transform: flipped ? 'rotateY(180deg)' : '', cursor: 'pointer' }} onClick={handleFlip}>
                {!flipped ? (
                    <>
                        <Styled.ImgPreview src={card.base64} alt={`Frente do Cartão`} />
                        {(card.title !== '' || card.urlAudio !== '') && (
                            <Styled.Card.Between>
                                {card.title !== '' && <Styled.Card.Title>{card.title}</Styled.Card.Title>}
                                {card.urlAudio !== '' && card.urlAudio !== null && (
                                    <>
                                        {!isPlaying ? (
                                            <Styled.Card.PlayAudio onClick={() => handlePlayAudio(audioRef)}>
                                                <IoPlayOutline size={22} color={Colors.Blue} />
                                            </Styled.Card.PlayAudio>
                                        ) : (
                                            <Styled.Card.PlayAudio onClick={() => handlePauseAudio(audioRef)}>
                                                <IoPauseOutline size={22} color={Colors.Blue} />
                                            </Styled.Card.PlayAudio>
                                        )}
                                    </>
                                )}
                                <audio ref={audioRef} src={card.urlAudio} />
                            </Styled.Card.Between>
                        )}
                        {card.description !== '' && <TextDangerouslySetInnerHtml dangerouslySetInnerHTML={{ __html: card.description }} />}

                        <Styled.Card.Footer>
                            <Styled.Card.Button onClick={handleFlip}>
                                <TfiReload />
                            </Styled.Card.Button>
                        </Styled.Card.Footer>
                    </>
                ) : (
                    <>
                        {card.baralhoBack?.base64 !== null && card.baralhoBack?.base64 !== '' && (
                            <Styled.ImgPreview src={card.baralhoBack?.base64} alt={`Frente do Cartão`} />
                        )}
                        {card.baralhoBack && (
                            <>
                                {(card.baralhoBack.title || card.baralhoBack.urlAudio) && (
                                    <Styled.Card.Between>
                                        {card.baralhoBack.title && <Styled.Card.Title>{card.baralhoBack.title}</Styled.Card.Title>}
                                        {card.baralhoBack.urlAudio && (
                                            <>
                                                {!isPlaying ? (
                                                    <Styled.Card.PlayAudio onClick={() => handlePlayAudio(audio2Ref)}>
                                                        <IoPlayOutline size={22} color={Colors.Blue} />
                                                    </Styled.Card.PlayAudio>
                                                ) : (
                                                    <Styled.Card.PlayAudio onClick={() => handlePauseAudio(audio2Ref)}>
                                                        <IoPauseOutline size={22} color={Colors.Blue} />
                                                    </Styled.Card.PlayAudio>
                                                )}
                                            </>
                                        )}
                                    </Styled.Card.Between>
                                )}
                                <audio ref={audio2Ref} src={card.baralhoBack.urlAudio} />
                            </>
                        )}

                        <TextDangerouslySetInnerHtml dangerouslySetInnerHTML={{ __html: card.baralhoBack?.description ?? '' }} />
                        <Styled.Card.Footer>
                            <Styled.Card.Button
                                style={{
                                    backgroundColor: Colors.White,
                                    color: Colors.Blue,
                                }}
                                onClick={handleFlip}
                            >
                                <TfiReload />
                            </Styled.Card.Button>
                        </Styled.Card.Footer>
                    </>
                )}
            </Styled.Card.Front>
        </Styled.Card.Container>
    );
};

interface IComponentBaralhoAdmin {
    componentInteractionCodigo: number;
    cardsModels: ComponentBaralhoModel[];
    quantityVisible: number;
    atualizarQuantityVisible: (n: number) => void;
}

const ComponentBaralhoAdmin = ({
    componentInteractionCodigo,
    cardsModels,
    quantityVisible,
    atualizarQuantityVisible,
}: IComponentBaralhoAdmin) => {
    const fileInputRefFront = useRef<HTMLInputElement | null>(null);
    const fileInputRefBack = useRef<HTMLInputElement | null>(null);

    const [cards, setCards] = useState<ComponentBaralhoModel[]>(cardsModels);
    const [frontImage, setFrontImage] = useState<string | null>(null);
    const [backImage, setBackImage] = useState<string | null>(null);
    const [titleFront, setTitleFront] = useState('');
    const [descriptionFront, setDescriptionFront] = useState('');
    const [titleBack, setTitleBack] = useState('');
    const [descriptionBack, setDescriptionBack] = useState('');
    const [audioFront, setAudioFront] = useState('');
    const [audioBack, setAudioBack] = useState('');

    const [loading, setLoading] = useState<boolean>(false);
    const [loadingDelete, setLoadingDelete] = useState<boolean>(false);

    // service
    const service = new ComponentBaralhoService();
    const serviceComponentInteractio = new ComponentInteractionService();

    //IsModEdit
    const [isModeEdit, setModeEdit] = useState<boolean>(false);
    const [indexCardUpdate, setIndexCardUpdate] = useState<number>(-1);

    const handleFrontImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setFrontImage(reader.result as string);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleBackImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setBackImage(reader.result as string);
            };
            reader.readAsDataURL(file);
        }
    };

    const clearForms = () => {
        setFrontImage(null);
        setBackImage(null);
        setTitleFront('');
        setDescriptionFront('');
        setTitleBack('');
        setDescriptionBack('');
        setAudioFront('');
        setAudioBack('');
        setModeEdit(false);
    };

    const handleCreateCard = async () => {
        setLoading(true);
        if (frontImage === '' || descriptionBack === '') {
            return ToastError('Campos obrigatórios vazios!');
        }

        const newCard = new ComponentBaralhoModel();
        newCard.title = titleFront; // ou titleBack dependendo de onde você quiser usar
        newCard.description = descriptionFront; // ou descriptionBack
        newCard.base64 = frontImage || ''; // A imagem da frente
        newCard.urlAudio = audioFront || '';
        newCard.isBack = false; // Defina como necessário
        newCard.baralhoBack = new ComponentBaralhoModel().fromJSON({
            title: titleBack,
            description: descriptionBack,
            base64: backImage,
            urlAudio: audioBack || '',
            isBack: true,
        });

        const { data } = await service.insert(componentInteractionCodigo, newCard);

        setCards([...cards, data]);

        // Limpar os estados após criar o cartão
        clearForms();
        setLoading(false);
    };

    const handleEditCard = (index: number) => {
        setModeEdit(true);
        setIndexCardUpdate(index);

        let cardsList = [...cards];
        let cardIndex = cardsList[index];

        setFrontImage(cardIndex.base64);
        setBackImage(cardIndex.baralhoBack?.base64 ?? '');
        setTitleFront(cardIndex.title);
        setTitleBack(cardIndex.baralhoBack?.title ?? '');
        setDescriptionFront(cardIndex.description);
        setDescriptionBack(cardIndex.baralhoBack?.description ?? '');
        setAudioFront(cardIndex.urlAudio);
        setAudioBack(cardIndex.baralhoBack?.urlAudio ?? '');
    };

    const deleteCardBaralho = async (card: ComponentBaralhoModel, index: number) => {
        setLoadingDelete(true);
        const { status } = await service.deleteByCardBaralho(card.id);

        const list = cards.filter(c => c.id != card.id);

        if (status === 204) {
            setCards(list);
        }
        setLoadingDelete(false);
    };

    const handleUpdateCard = async () => {
        setLoading(true);

        if (frontImage === '' || descriptionBack === '') {
            return ToastError('Campos obrigatórios vazios!');
        }

        let cardAtualization = [...cards];

        let card = cardAtualization[indexCardUpdate];

        if (card) {
            card.title = titleFront;
            card.description = descriptionFront;
            card.base64 = frontImage ?? '';
            card.urlAudio = audioFront;
            card.isBack = false;

            if (card.baralhoBack) {
                card.baralhoBack.title = titleBack;
                card.baralhoBack.description = descriptionBack;
                card.baralhoBack.base64 = backImage ?? '';
                card.baralhoBack.urlAudio = audioBack;
                card.baralhoBack.isBack = true;
            }
        }

        cardAtualization[indexCardUpdate] = card;

        const { data, status } = await service.update(componentInteractionCodigo, card);

        if (status === 200) {
            ToastSuccess('Baralho atualizado!');
        }

        // Limpar os estados após criar o cartão
        setLoading(false);
        clearForms();
    };

    const atualizaQuantity = async (quantity: number) => {
        await serviceComponentInteractio.atualizarQuantity(componentInteractionCodigo, quantity);
        atualizarQuantityVisible(quantity);
    };

    useEffect(() => {
        setCards(cardsModels);
    }, [cardsModels]);

    return (
        <Styled.Container>
            <Styled.Row>
                <Styled.Column>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleFrontImageChange}
                        ref={fileInputRefFront}
                        style={{ display: 'none' }} // Oculta o input
                    />
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleBackImageChange}
                        ref={fileInputRefBack}
                        style={{ display: 'none' }} // Oculta o input
                    />
                    <Styled.Title>Criei um novo cartão</Styled.Title>
                    <Styled.Form>
                        {frontImage ? (
                            <>
                                <Styled.ImgPreview
                                    src={frontImage}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => fileInputRefFront.current?.click()}
                                />
                                <Styled.Button
                                    style={{ backgroundColor: Colors.Red, color: Colors.White, border: Colors.Red }}
                                    onClick={() => setFrontImage('')}
                                >
                                    <MdDelete /> Remover imagem
                                </Styled.Button>
                            </>
                        ) : (
                            <Styled.Button onClick={() => fileInputRefFront.current?.click()}>
                                <MdAdd /> Adicione uma imagem
                            </Styled.Button>
                        )}
                        <Styled.SubTitle>Título:</Styled.SubTitle>
                        <Input size="large" placeholder="Digite o título" value={titleFront} onChange={e => setTitleFront(e.target.value)} />
                        <Styled.SubTitle>URL do áudio:</Styled.SubTitle>
                        <Input size="large" placeholder="Cole a URL do áudio" value={audioFront} onChange={e => setAudioFront(e.target.value)} />

                        <Styled.SubTitle>Descrição:</Styled.SubTitle>
                        <ReactQuill
                            style={{ marginBottom: '20px', width: '460px', height: '200px' }}
                            theme="snow"
                            placeholder="Digite aqui a descrição do cartão frontal."
                            modules={editorConfig.modules2}
                            formats={editorConfig.formats2}
                            value={descriptionFront}
                            onChange={val => {
                                setDescriptionFront(val);
                            }}
                        />
                        {/* <TextArea
                            placeholder="Digite aqui a descrição do cartão frontal."
                            rows={4}
                            value={descriptionFront}
                            onChange={e => setDescriptionFront(e.target.value)}
                        /> */}
                        <Divider />
                        <Styled.Title>Parte de trás</Styled.Title>
                        {backImage ? (
                            <>
                                <Styled.ImgPreview
                                    src={backImage}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => fileInputRefBack.current?.click()}
                                />
                                <Styled.Button
                                    style={{ backgroundColor: Colors.Red, color: Colors.White, border: Colors.Red }}
                                    onClick={() => setBackImage('')}
                                >
                                    <MdDelete /> Remover imagem
                                </Styled.Button>
                            </>
                        ) : (
                            <Styled.Button onClick={() => fileInputRefBack.current?.click()}>
                                <MdAdd /> Adicione uma imagem
                            </Styled.Button>
                        )}
                        <Styled.SubTitle>Título:</Styled.SubTitle>
                        <Input size="large" placeholder="Digite o título" value={titleBack} onChange={e => setTitleBack(e.target.value)} />
                        <Styled.SubTitle>URL do áudio:</Styled.SubTitle>
                        <Input size="large" placeholder="Cole a URL do áudio" value={audioBack} onChange={e => setAudioBack(e.target.value)} />

                        <Styled.SubTitle>Descrição:</Styled.SubTitle>
                        <ReactQuill
                            style={{ width: '460px', height: '200px' }}
                            theme="snow"
                            placeholder="Digite aqui a descrição do cartão frontal."
                            modules={editorConfig.modules2}
                            formats={editorConfig.formats2}
                            value={descriptionBack}
                            onChange={val => {
                                setDescriptionBack(val);
                            }}
                        />

                        <Styled.Button
                            style={{ backgroundColor: Colors.Blue, color: Colors.White, marginTop: 100 }}
                            onClick={() => (isModeEdit ? handleUpdateCard() : handleCreateCard())}
                        >
                            {loading ? <div className="loaderCreater" /> : isModeEdit ? 'Atualizar' : 'Criar'}
                        </Styled.Button>
                    </Styled.Form>
                    <Divider />
                    <Styled.SubTitle>Quantidade de cartões visiveis:</Styled.SubTitle>
                    <InputNumber min={1} max={2} defaultValue={1} value={quantityVisible} onChange={v => atualizaQuantity(v ?? -1)} />
                </Styled.Column>
                <Styled.Divider />
                <Styled.Column>
                    <Styled.Title>Cartões criados</Styled.Title>
                    <Styled.Row style={{ flexWrap: 'wrap' }}>
                        {cards.map((card, index) => (
                            <CardBaralho
                                key={index}
                                card={card}
                                setEdit={() => handleEditCard(index)}
                                handleClickDelete={() => deleteCardBaralho(card, index)}
                                loadingDelete={loadingDelete}
                            />
                        ))}
                    </Styled.Row>
                </Styled.Column>
            </Styled.Row>
        </Styled.Container>
    );
};

export default ComponentBaralhoAdmin;
