

export enum ComponentInteractionEnum {
    AUDIO = 'AUDIO',
    TEXT = 'TEXT',
    SANFONA = 'SANFONA',
    QUIZ = 'QUIZ',
    CORRESPONDENT = 'CORRESPONDENT',
    CARROSSEL = 'CARROSSEL',
    ABAS = 'ABAS',
    CARD_BARALHO = 'CARD_BARALHO',
    REGUA_AVALIATIVA = 'REGUA_AVALIATIVA',
    TIMELINE = 'TIMELINE',
    NENHUM = ''
}