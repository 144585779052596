import styled from 'styled-components';

import Colors from 'enums/Colors';
import { ColorHelp } from 'core/util/ColorHelp';
import FullCss, { MediaQuery } from 'core/util/FullCss';
import { MobileMaxWidth } from 'constants/Dimensions';

export namespace DiaryCardStyle {
    interface IContainer {
        selected?: boolean;
    }
    export const Container = styled.div<IContainer>(({ selected = false }: IContainer) => {
        return new FullCss(
            {
                top: '422px',
                left: '210px',
                width: '312px',
                height: '73px',
                display: 'flex',
                margin: '10px 0px',
                padding: '10px 5px',
                alignItems: 'center',
                justifyContent: 'center',
                border: `1px solid ${Colors.Grey8}`,
                background: selected ? Colors.SoftBlue + ColorHelp.decToHexTransparency(0.15) : '',
                // border: '1px red solid',
            },
            new MediaQuery(':hover', { background: Colors.SoftBlue + ColorHelp.decToHexTransparency(0.07) })
        ).getCss();
    });

    export const Header = styled.div({
        display: 'flex',
        marginBottom: 10,
    });

    export const Title = styled.h2({
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '14px',
        fontStyle: 'normal',
        color: Colors.Grey9,
        fontFamily: 'Barlow',
    });

    export const DateContainer = styled(Title)({
        marginLeft: 10,
        fontSize: '10px',
        lineHeight: '12px',
        fontWeight: 'normal',
        color: Colors.LightGrey,
    });

    export const Left = styled.div({
        flex: 1,
        marginLeft: 20,
        marginRight: 10,
        display: 'flex',
        cursor: 'default',
        flexDirection: 'column',
        height: '100%',
    });

    const SwipeDiv = styled.div({
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        color: Colors.White,

        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '14px',
        fontStyle: 'normal',
        textAlign: 'center',
        fontFamily: 'Barlow',
    });

    export const DeleteDiv = styled(SwipeDiv)({
        paddingRight: 15,
        justifyContent: 'flex-end',
        background: Colors.DeleteRed,
    });

    export const CloneDiv = styled(SwipeDiv)({
        paddingLeft: 15,
        justifyContent: 'flex-start',
        background: Colors.CloneGreen,
    });

    export const GenericSwipeDiv = styled.div({
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
    });

    export const ContainerCard = styled.div<{ selected: boolean }>`
        background-color: ${props => (props.selected ? '#003C78' : 'white')};
        color: ${props => (!props.selected ? '#003C78' : 'white')};
        display: flex;
        padding: 10px 10px;
        margin: 10px auto;
        width: 95%;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            margin-bottom: 0px;
            padding-bottom: 15px;
        }
    `;

    export const Column = styled.div`
        display: flex;
        flex-direction: column;
        border-radius: 7px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.15);
            margin: 15px auto;
            margin-top: 7px;
        }
    `;

    export const TitleContainerCard = styled.div`
        flex-grow: 1;
    `;

    export const ButtonContainerCard = styled.div`
        min-width: fit-content;

     
        @media screen and (max-width: 1200px) {
            .desk-only {
                display: none;
            }
        }

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            .mobile-only {
                display: block;
            }
        }
    `;

    export const IconCard = styled.span`
        padding: 5px;
        cursor: pointer;
    `;

    export const TitleCard = styled.p`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 7px;
        cursor: pointer;

        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    `;

    export const SubTitleCard = styled.p<{ selected: boolean }>`
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        cursor: pointer;
        color: ${props => (!props.selected ? '#8D8D8D' : 'white')};
    `;

    export const MenuContainer = styled.div`
        position: relative;
        margin-top: 10px;
        padding-bottom: 10px;

        display: flex;
        justify-content: space-around;
    `;

    export const ButtonAction = styled.button`
        width: 72px;
        height: 34px;
        left: 8px;
        top: 6px;
        background: #00a8e9;
        border-radius: 6px;
        border: none;
        outline: none;
        color: white;
    `;
}
