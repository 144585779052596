import ComponentReguaAvaliativaOptions from 'models/componentInteraction/ComponentReguaAvaliativaOptions';
import { GenericService } from '../Generic.service';
import ComponentReguaAvaliativaQuestions from 'models/componentInteraction/ComponentReguaAvaliativaQuestions';

export class ComponentReguaAvaliativaQquestionsService extends GenericService {
    readonly URI: string = '/v1/componentReguaAvaliativaQuestions';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    async atualizarOption(dto: ComponentReguaAvaliativaOptions, codigoQuestion: number) {
        return await this.put('' + codigoQuestion, dto);
    }
    async atualizarInformationQuestion(dto: ComponentReguaAvaliativaQuestions, codigoRegua: number) {
        return await this.put('information/' + codigoRegua, dto);
    }

    async insert(dto: ComponentReguaAvaliativaQuestions, codigoRegua: number) {
        return await this.post('' + codigoRegua, dto);
    }

    async deleteOption(codigoOption: number) {
        return await this.deleteByUrl(codigoOption, 'option');
    }
    async deleteQuestion(codigoQuestion: number) {
        return await this.delete(codigoQuestion);
    }
}
