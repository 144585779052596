import Colors from 'enums/Colors';
import React from 'react';
import styled from 'styled-components';
import { IoSchoolOutline } from 'react-icons/io5';
import { TbCurrencyDollarOff } from 'react-icons/tb';
import { MdOutlineOpenInNew, MdOutlineWatchLater } from 'react-icons/md';
import Logo from '../assets/dialogos/LogoPatners.svg';
import { PrimaryButton } from 'components/UI/Buttons';
import { useHistory } from 'react-router-dom';
import { fetchStorageFile } from 'hooks/StoreFiles';

const Styled = {
    Container: styled.div`
        display: flex;
        flex-direction: column;
        min-height: 520px;
        padding-bottom: 40px;
    `,

    Content: styled.div`
        display: flex;
        flex-direction: row-reverse;
        padding-top: 40px;
        padding-left: 70px;
        gap: 5rem;

        @media screen and (max-width: 940px) {
            flex-direction: column-reverse;
            padding-left: 40px;
            gap: 0.2rem;
        }
        @media screen and (max-width: 700px) {
            padding-left: 20px;
        }
    `,

    Title: styled.h1`
        font-family: 'Barlow';
        font-size: 34px;
        font-weight: 700;
        line-height: 51px;
        letter-spacing: -0.019em;
        color: ${Colors.Blue};
        text-align: left;
        max-width: 84%;
    `,

    Description: styled.p`
        font-family: 'Barlow';
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.019em;
        text-align: left;
        max-width: 84%;
        margin-bottom: 20px;
        margin-top: 20px;
        color: ${Colors.Blue};

        @media screen and (max-width: 1250px) {
            width: 80%;
        }

        @media screen and (max-width: 1164px) {
            font-size: 16px;
            max-width: 100%;
        }
    `,

    Column: styled.div`
        display: flex;
        flex-direction: column;
    `,

    RowBetween: styled.div`
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;

        @media screen and (max-width: 1160px) {
            flex-direction: column-reverse;
            align-items: start;
        }
    `,

    Certificated: {
        Container: styled.div`
            display: flex;
            align-items: center;
            gap: 1rem;
            height: 60px;
            padding: 20px;
            font-size: 16px;
            border-radius: 20px;
            background-color: ${Colors.Blue};
            color: ${Colors.White};
            margin-right: 20px;

            @media screen and (max-width: 1280px) {
                width: 400px;
            }
            @media screen and (max-width: 1160px) {
                width: 350px;
            }

            @media screen and (max-width: 1160px) {
                width: auto;
                margin-bottom: 20px;
            }
        `,
    },

    Time: styled.div`
        display: flex;
        gap: 1rem;
    `,

    SpaceCenter: styled.div`
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-size: 20px;
        color: ${Colors.Blue};
    `,

    Img: styled.img`
        width: 1200px;
        height: 400px;
        margin: 10px 0px;
        border-radius: 4px;
        object-fit: cover;

        @media screen and (max-width: 1220px) {
            width: 95%;
        }
      
    `,

    ButtonBlue: styled.button`
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 203px;
        height: 54px;
        padding: 10px 20px 10px 20px;
        gap: 10px;
        opacity: 0px;
        background-color: ${Colors.Blue};
        color: ${Colors.White};
        border: none;
        border-radius: 4px;
    `,
};

interface IPartnersExclusivo {
    title: string;
    description: string;
    img: string;
    button: {
        label: string;
        url: string;
    };
}

const PartnersExclusivo = ({ title, description, img, button }: IPartnersExclusivo) => {
    const history = useHistory();

    const imgStorage = fetchStorageFile(img);

    return (
        <Styled.Container>
            <Styled.Content>
                <Styled.Column>
                    <Styled.Title>{title}</Styled.Title>
                    <Styled.Description
                        dangerouslySetInnerHTML={{
                            __html: description,
                        }}
                    />
                    <Styled.ButtonBlue onClick={() => history.push(button.url)} style={{ width: '250px', margin: '10px 0' }}>
                        {button.label} <MdOutlineOpenInNew />
                    </Styled.ButtonBlue>
                </Styled.Column>
                {/* <Styled.Certificated.Container>
                        {' '}
                        <IoSchoolOutline size={25} color={Colors.Yellow} /> Cerificado pelo IEA USP Ribeirão Preto e Instituto Ayrton Senna
                    </Styled.Certificated.Container> */}

                {/* <Styled.Time>
                    <Styled.SpaceCenter>
                        <TbCurrencyDollarOff color={Colors.Yellow} /> Grátis
                    </Styled.SpaceCenter>
                    <Styled.SpaceCenter>
                        {' '}
                        <MdOutlineWatchLater color={Colors.Yellow} /> X horas
                    </Styled.SpaceCenter>
                </Styled.Time> */}

                <Styled.Img src={imgStorage} />
            </Styled.Content>
        </Styled.Container>
    );
};

export default PartnersExclusivo;
