import { Button, ColorPicker, ColorPickerProps, ConfigProvider, GetProp, Popover, Row, Switch, Typography, UploadProps } from 'antd';
import Colors from 'enums/Colors';
import ComponentItemCarrosselInteraction from 'models/componentInteraction/ComponentItemCarrosselInteraction';
import React, { useEffect, useRef, useState } from 'react';
import { FaLongArrowAltLeft, FaLongArrowAltRight } from 'react-icons/fa';
import styled from 'styled-components';
import { scroolToHeigth } from 'pages/gestao_paginas/new-edit-recurso/conteudo-recurso/AdminGestaoPaginaConteudoRecurso';
import ReactQuill from 'react-quill';
import editorConfig from 'pages/diarioBordo/components/textEditor/editorConfig';
import { blue, presetPalettes, yellow } from '@ant-design/colors';
import { ComponentItemCarrosselService } from 'core/http/service/componetInteraction/ComponentItemCarrossel.service';

const Styled = {
    Container: styled.div``,
};

const scrollHeight = () => {
    setTimeout(() => {
        scroolToHeigth();
    }, 100);
};

type Presets = Required<ColorPickerProps>['presets'][number];

const genPresets = (presets = presetPalettes) => Object.entries(presets).map<Presets>(([label, colors]) => ({ label, colors }));

interface ICarrosselInteractionAdmin {
    items: ComponentItemCarrosselInteraction[];
    handleInfosCarrosel: (items: ComponentItemCarrosselInteraction[]) => void;
}

const CarrosselInteractionAdmin = ({ items, handleInfosCarrosel }: ICarrosselInteractionAdmin) => {
    const [carrosselItem, setCarrosselItem] = useState<ComponentItemCarrosselInteraction[]>([]);
    const [indexNav, setIndexNav] = useState<number>(0);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const presets = genPresets({ Azul: blue, Amarelo: yellow });

    const service = new ComponentItemCarrosselService();

    const handleNavigateItem = (next: boolean) => {
        if (!next) {
            if (indexNav > 0) {
                setIndexNav(indexNav - 1);
            }
        } else {
            if (indexNav + 1 < carrosselItem.length) {
                setIndexNav(indexNav + 1);
            }
        }

    };

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const file = e.target.files?.[0]; // Usando optional chaining
        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                const base64String = reader.result as string; // O resultado é do tipo string

                setCarrosselItem((prevState: any) => {
                    const updatedItems = prevState.map((item: ComponentItemCarrosselInteraction, indexItem: number) =>
                        index === indexItem ? { ...item, base64: base64String, filepath: null } : item
                    );
                    handleInfosCarrosel(updatedItems); // Atualizar a outra lista
                    return updatedItems;
                });
            };

            reader.readAsDataURL(file); // Inicia a leitura do arquivo e converte para Base64
        }
    };

    const handleCheckedTextOrImg = (checked: boolean, index: number) => {
        setCarrosselItem((prevState: any) => {
            const updatedItems = prevState.map((item: ComponentItemCarrosselInteraction, indexItem: number) =>
                index === indexItem ? { ...item, type: checked ? 'I' : 'T' } : item
            );
            handleInfosCarrosel(updatedItems);
            return updatedItems;
        });
    };
    const handleColorBg = (color: string, index: number) => {
        setCarrosselItem((prevState: any) => {
            const updatedItems = prevState.map((item: ComponentItemCarrosselInteraction, indexItem: number) =>
                index === indexItem ? { ...item, colorBg: color } : item
            );
            handleInfosCarrosel(updatedItems);
            return updatedItems;
        });
    };

    const handleText = (value: string, index: number) => {
        setCarrosselItem((prevState: any) => {
            const updatedItems = prevState.map((item: ComponentItemCarrosselInteraction, indexItem: number) =>
                index === indexItem ? { ...item, text: value } : item
            );
            handleInfosCarrosel(updatedItems);
            return updatedItems;
        });
    };

    const handleDelete = async () => {
        setIndexNav(prevIndex => (prevIndex > 0 ? prevIndex - 1 : 0));
        const itens = [...carrosselItem]; // Crie uma cópia para modificar

        if (itens.length > 1) {
            const itemToDelete = itens[indexNav];
            itens.splice(indexNav, 1); // Remova o item da cópia

            const response = await service.deleteById(itemToDelete.id);

            if (response.status === 204) {
                setCarrosselItem(itens);
                handleInfosCarrosel(itens);

                // Ajuste o índice de navegação após a remoção
            }
        }
    };

    const handleDivClick = () => {
        fileInputRef.current?.click();
    };

    const handleNewItem = () => {
        const item = new ComponentItemCarrosselInteraction();
        item.type = 'I';

        setCarrosselItem(prevItems => {
            const updatedItems = [...prevItems, item];
            handleInfosCarrosel(updatedItems);
            return updatedItems;
        });
        setIndexNav(carrosselItem.length ?? 0);
        scrollHeight();
    };

    // useEffect(() => {
    //     handleInfosCarrosel(carrosselItem);
    // }, [carrosselItem]);

    useEffect(() => {
        setCarrosselItem(items);
    }, [items]);

    return (
        <Styled.Container>
            <Typography.Title level={4} style={{ marginTop: '20px' }}>
                Carrossel
            </Typography.Title>

            {Array.isArray(carrosselItem) &&
                carrosselItem.length > 0 &&
                carrosselItem.map((item, index) => {
                    if (item && item.type != null && item.type === 'I' && index === indexNav) {
                        return (
                            <>
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={e => handleImageChange(e, index)}
                                    ref={fileInputRef}
                                    style={{ display: 'none' }} // Oculta o input
                                />
                                {(item.base64 === '' || item.base64 === null) && (
                                    <div
                                        key={index}
                                        onClick={handleDivClick} // Adiciona a funcionalidade de clique
                                        style={{
                                            border: '1px dashed grey',
                                            width: '600px',
                                            height: '400px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            cursor: 'pointer',
                                            margin: '10px 0px',
                                            color: Colors.Blue,
                                            fontWeight: 600,
                                        }}
                                    >
                                        Clique aqui para adicionar uma imagem!
                                    </div>
                                )}
                                {item.base64 !== '' && item.base64 !== null && (
                                    <div style={{ cursor: 'pointer', width: '600px', height: '460px' }} onClick={handleDivClick}>
                                        <img
                                            src={item.base64}
                                            alt="Preview"
                                            style={{
                                                height: '460px',
                                                width: '620px',
                                                objectFit: 'cover',
                                                margin: '20px 0px',
                                                borderRadius: '20px',
                                            }}
                                        />
                                    </div>
                                )}
                            </>
                        );
                    }
                    if (item && item.type != null && item.type === 'T' && index === indexNav) {
                        return (
                            <>
                                <ReactQuill
                                    style={{ marginBottom: '20px' }}
                                    theme="snow"
                                    modules={editorConfig.modules2}
                                    formats={editorConfig.formats2}
                                    value={item.text}
                                    onChange={val => {
                                        handleText(val, indexNav);
                                    }}
                                />
                            </>
                        );
                    }
                })}

            <Row style={{ gap: '1rem', display: 'flex', alignContent: 'center', marginTop: '26px' }}>
                <Button onClick={handleNewItem} type="dashed" style={{ width: '200px', backgroundColor: Colors.Yellow }}>
                    Adicionar novo item
                </Button>
                <Switch
                    style={{ display: carrosselItem.length > 0 ? 'flex' : 'none', position: 'relative', top: '5px' }}
                    checkedChildren="Imagem"
                    unCheckedChildren="Texto"
                    onChange={c => handleCheckedTextOrImg(c, indexNav)}
                    checked={carrosselItem ? carrosselItem.length > 0 && carrosselItem[indexNav].type === 'I' : false}
                />

                {carrosselItem.length > 0 && carrosselItem[indexNav].type === 'T' && (
                    <Popover content={<p>Mudar a cor de fundo.</p>} title="Cores">
                        <ColorPicker
                            presets={presets}
                            onChange={c => handleColorBg(c.toHexString(), indexNav)}
                            value={carrosselItem[indexNav].colorBg ?? ''}
                        />
                    </Popover>
                )}

                <div
                    style={{
                        display: carrosselItem && carrosselItem.length > 0 ? 'flex' : 'none',
                        gap: '0.5rem',
                        marginLeft: '20px',
                        alignItems: 'center',
                    }}
                >
                    <ConfigProvider
                        button={{
                            style: {
                                width: 60,
                                backgroundColor: Colors.DarkBlue,
                            },
                        }}
                    >
                        {' '}
                        <Button onClick={() => handleNavigateItem(false)} type="primary">
                            <FaLongArrowAltLeft size={22} />
                        </Button>
                        <p style={{ fontWeight: 600, display: 'flex', width: '40px' }}>{`${indexNav + 1} / ${
                            carrosselItem && carrosselItem.length
                        }`}</p>
                        <Button onClick={() => handleNavigateItem(true)} type="primary">
                            <FaLongArrowAltRight size={16} />
                        </Button>
                        {carrosselItem.length > 1 && (
                            <Button
                                onClick={() => handleDelete()}
                                type="dashed"
                                style={{ width: '200px', backgroundColor: Colors.InfoRed, color: '#FFF' }}
                            >
                                Deletar
                            </Button>
                        )}
                    </ConfigProvider>
                </div>
            </Row>
        </Styled.Container>
    );
};

export default CarrosselInteractionAdmin;
