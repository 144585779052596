import { jsonProperty, Serializable } from 'ts-serializable';

class ComponentBaralhoModel extends Serializable {
    @jsonProperty(Number)
    id: number = -1;

    @jsonProperty(String)
    title: string = '';

    @jsonProperty(String)
    description: string = '';

    @jsonProperty(String)
    filepath: string = '';

    @jsonProperty(String)
    base64: string = '';

    @jsonProperty(String)
    urlAudio: string = '';

    @jsonProperty(Boolean)
    isBack: boolean = false;

    baralhoBack: ComponentBaralhoModel | null = null;
}

export default ComponentBaralhoModel;
