import { jsonProperty, Serializable } from 'ts-serializable';
import ComponentReguaAvaliativaFeedbacks from './ComponentReguaAvaliativaFeedbacks';
import ComponentReguaAvaliativaQuestions from './ComponentReguaAvaliativaQuestions';

class ComponentReguaAvaliativaModel extends Serializable {
    @jsonProperty(Number)
    id: number = -1;

    @jsonProperty(String)
    titulo: string = '';

    @jsonProperty(String)
    textTituloAux: string = '';

    @jsonProperty(Boolean)
    circuleSimboly: boolean = true;

    questions: ComponentReguaAvaliativaQuestions[] = [];

    feedbacks: ComponentReguaAvaliativaFeedbacks[] = [];
}

export default ComponentReguaAvaliativaModel;
