import { PrimaryButton } from 'components/UI/Buttons';
import Colors from 'enums/Colors';
import React from 'react';
import styled from 'styled-components';
import { BsArrowDownSquareFill } from 'react-icons/bs';
import { IsCustomMaxWidth, IsCustomMinWidth } from 'core/util/WindowSize';
import { fetchStorageFile } from 'hooks/StoreFiles';

const Styled = {
    Container: styled.div<{ isBlue?: boolean }>`
        display: flex;
        background-color: ${({ isBlue }) => (isBlue ? Colors.Blue : Colors.White)};
        color: ${({ isBlue }) => (isBlue ? Colors.White : Colors.Blue)};

        @media screen and (max-width: 1920px) {
            height: auto;
        }

        @media screen and (max-width: 1100px) {
            flex-direction: column-reverse;
        }
    `,

    ContentHeader: {
        Container: styled.div`
            display: flex;
            align-items: center;
            gap: 0.5rem;

            @media screen and (max-width: 500px) {
                flex-direction: column;
                align-items: start;
            }
        `,

        Logo: styled.img`
            width: 100px;
            height: 90px;
            border-radius: 10px;
            padding: 8px;
            object-fit: fill;
            background-color: ${Colors.White};
        `,

        Column: styled.div`
            display: flex;
            flex-direction: column;
        `,
    },

    Column: styled.div`
        display: flex;
        align-items: center;
        gap: 1rem;
        flex-direction: column;
        margin: 60px;

        @media screen and (max-width: 1100px) {
            margin: 0px;
        }
    `,

    Content: styled.div`
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        padding-top: 40px;
        padding-left: 70px;
        padding-bottom: 40px;
        max-width: 50%;

        @media screen and (max-width: 1920px) {
            max-width: 70%;
        }

        @media screen and (max-width: 1330px) {
            max-width: 60%;
        }

        @media screen and (max-width: 1100px) {
            max-width: 90%;
        }

        @media screen and (max-width: 990px) {
            padding-left: 40px;
        }

        @media screen and (max-width: 870px) {
            width: 100%;
        }
    `,

    Img: styled.img`
        height: 334px;
        width: 575px;
        border-radius: 10px;

        object-fit: cover;

        @media screen and (max-width: 870px) {
            width: 100%;
        }
    `,

    TextDestaque: styled.h2`
        font-family: 'Barlow';
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: -0.01em;
        text-align: left;
        color: ${Colors.SoftBlue};
        text-underline-position: 'from-font';
        text-decoration-skip-ink: none;
    `,

    Title: styled.h1`
        font-family: 'Barlow';
        font-size: 34px;
        font-weight: 700;
        line-height: 51px;
        letter-spacing: -0.019em;
        text-align: left;
        text-transform: uppercase;

        @media screen and (max-width: 500px) {
            font-size: 24px;
        }
    `,

    Description: styled.p`
        font-family: 'Barlow';
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.019em;
        text-align: left;
        margin-bottom: 20px;
        margin-top: 20px;
        max-width: 70%;

        ul {
            list-style-type: disc; /* ou 'circle', 'square', etc. */
            padding-left: 20px; /* Adiciona um recuo à lista */
        }

        li {
            margin-bottom: 10px; /* Adiciona espaçamento entre os itens da lista */
        }

        @media screen and (max-width: 1920px) {
            max-width: 90%;
        }

        @media screen and (max-width: 1250px) {
            width: 100%;
        }

        @media screen and (max-width: 1164px) {
            font-size: 16px;
            max-width: 100%;
        }
    `,

    Button: styled.button`
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${Colors.White};
        color: ${Colors.Blue};
        width: 414px;
        height: 56px;
        border-radius: 4px;
        margin: 0;
        gap: 1rem;
        border: none;
        margin-top: 28px;

        @media screen and (max-width: 500px) {
            width: 100%;
        }
    `,
};

interface IOverviewExclusivo {
    isBlue?: boolean;
    img: string;
    logo: string;
    title: string;
    description: string;
    arquivo: string;
    nome_arquivo: string;
}

const OverviewExclusivo = ({ isBlue, logo, img, title, description, arquivo, nome_arquivo }: IOverviewExclusivo) => {
    const imgStorage = fetchStorageFile(img);
    const logoStorage = fetchStorageFile(logo);

    function sanitizeFileName(fileName: string): string {
        // Aqui, você pode adicionar uma lógica para remover caracteres não permitidos
        return fileName.replace(/[^\w\s\.\-]/gi, '');
    }

    // Mapeamento de tipos MIME para extensões de arquivo
    const mimeToExtension = (mime: string): string => {
        const mimeTypes: { [key: string]: string } = {
            'application/pdf': '.pdf',
            'application/vnd.ms-powerpoint': '.ppt',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': '.pptx',
            'application/msword': '.doc',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
            'image/jpeg': '.jpg',
            'image/png': '.png',
            'image/gif': '.gif',
            'text/plain': '.txt',
            'application/zip': '.zip',
            'application/x-rar-compressed': '.rar',
            'application/json': '.json',
            // Adicione outros tipos conforme necessário
        };

        return mimeTypes[mime] || ''; // Retorna uma string vazia caso o tipo não tenha correspondência
    };

    const downloadMaterial = async () => {
        try {
            if (!arquivo) {
                console.error('Arquivo não disponível');
                return;
            }

            const fileUrl = fetchStorageFile(arquivo); // Supondo que fetchStorageFile seja uma função já definida
            const response = await fetch(fileUrl);
            if (!response.ok) throw new Error('Network response was not ok');

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            const fileExtension = mimeToExtension(blob.type); // Obter a extensão do arquivo com base no tipo MIME
            const fileName = sanitizeFileName(nome_arquivo) + (fileExtension || '.bin'); // Adiciona .bin se não encontrar uma correspondência

            const link = document.createElement('a');
            link.href = url;
            link.download = fileName; // Nome do arquivo com a extensão correta
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url); // Limpa a URL criada
        } catch (error) {
            console.error('Erro ao baixar o arquivo:', error);
        }
    };

    return (
        <Styled.Container isBlue={isBlue}>
            <Styled.Content>
                <div>
                    <Styled.ContentHeader.Container>
                        <Styled.ContentHeader.Logo src={logoStorage} />
                        <Styled.ContentHeader.Column>
                            <Styled.TextDestaque>Visão Geral sobre a solução</Styled.TextDestaque>
                            <Styled.Title>{title}</Styled.Title>
                        </Styled.ContentHeader.Column>
                    </Styled.ContentHeader.Container>

                    <Styled.Description
                        dangerouslySetInnerHTML={{
                            __html: description,
                        }}
                    />
                </div>
                {IsCustomMaxWidth(1100) && (
                    <Styled.Button onClick={downloadMaterial}>
                        <BsArrowDownSquareFill color={Colors.Yellow} size={26} /> {`Baixe o documento de visão geral`}
                    </Styled.Button>
                )}
            </Styled.Content>
            <Styled.Column>
                <Styled.Img src={imgStorage} />
                {IsCustomMinWidth(1100) && (
                    <Styled.Button onClick={downloadMaterial}>
                        <BsArrowDownSquareFill color={Colors.Yellow} size={26} /> {`Baixe o documento de visão geral`}
                    </Styled.Button>
                )}
            </Styled.Column>
        </Styled.Container>
    );
};

export default OverviewExclusivo;
