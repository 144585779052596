
type IArquivos = {
    arquivo: any;
    nome: string;
};

type IMaterial = {
    isPaste?: boolean;
    titlePaste?: string;
    arquivos: IArquivos[];
};
type TItem = {
    title: string;
    materiais: IMaterial[];
    
};

export const ListMateriaisDialogosSocioemocionais: TItem[] = [
    {
        title: 'Modelo pedagógico Duplo Foco',
        materiais: [
            {
                arquivos: [{ nome: 'duplo_foco_modelo_pedagogico_2024', arquivo: "anexo_storage_files_3628613198955441378.pdf_2024-11-26-1732645373964.pdf" }],
            },
        ],
    },
    {
        title: 'Banco de planejamentos docentes inspiracionais',
        materiais: [
            {
                isPaste: true,
                titlePaste: 'Artes Linguagens',
                arquivos: [
                    { nome: 'VF_DIGITAL_DSE_ARTES_ARQUIVO_COMPLETO', arquivo: "anexo_storage_files_2527037867760065387.pdf_2024-11-27-1732715238893.pdf" },
                    { nome: 'VF_DIGITAL_DSE_ARTES_ENSINO-FUNDAMENTAL', arquivo: "anexo_storage_files_1926202253679560114.pdf_2024-11-27-1732715287735.pdf" },
                    { nome: 'VF_DIGITAL_DSE_ARTES_ENSINO-MEDIO', arquivo: "anexo_storage_files_70416626869600189.pdf_2024-11-27-1732715309635.pdf" },
                ],
            },
            {
                isPaste: true,
                titlePaste: 'Ciências das Natureza',
                arquivos: [
                    { nome: 'VF_DIGITAL_DSE_CIENCIAS_ARQUIVO_COMPLETO', arquivo: "anexo_storage_files_17290508717946049659.pdf_2024-11-27-1732715356616.pdf" },
                    { nome: 'VF_DIGITAL_DSE_CIENCIAS_ENSINO-FUNDAMENTAL', arquivo: "anexo_storage_files_8284341600268362088.pdf_2024-11-27-1732715384814.pdf" },
                    { nome: 'VF_DIGITAL_DSE_CIENCIAS_ENSINO-MEDIO', arquivo: "anexo_storage_files_1396562196958938730.pdf_2024-11-27-1732715416630.pdf" },
                ],
            },
            {
                isPaste: true,
                titlePaste: 'Educação Física',
                arquivos: [
                    { nome: 'VF_DIGITAL_DSE_EDUCACAO_FISICA_ARQUIVO_COMPLETO', arquivo: "anexo_storage_files_2419322089643120486.pdf_2024-11-27-1732715461567.pdf" },
                    { nome: 'VF_DIGITAL_DSE_EDUCACAO_FISICA_ENSINO-FUNDAMENTAL', arquivo: "anexo_storage_files_2060380505009625369.pdf_2024-11-27-1732715488805.pdf" },
                    { nome: 'VF_DIGITAL_DSE_EDUCACAO_FISICA_ENSINO-MEDIO', arquivo: "anexo_storage_files_4679165426639291414.pdf_2024-11-27-1732715513319.pdf" },
                ],
            },
            {
                isPaste: true,
                titlePaste: 'Ensino Relegioso',
                arquivos: [{ nome: 'VF_DIGITAL_DSE_ENSINO_RELIGIOSO_COMPLETO', arquivo: "anexo_storage_files_18371778116684288483.pdf_2024-11-27-1732715548430.pdf" }],
            },
            {
                isPaste: true,
                titlePaste: 'Filosofia',
                arquivos: [{ nome: 'VF_DIGITAL_DSE_FILOSOFIA_COMPLETO', arquivo: "anexo_storage_files_3615959800773189493.pdf_2024-11-27-1732715581832.pdf" }],
            },
            {
                isPaste: true,
                titlePaste: 'Física',
                arquivos: [{ nome: 'VF_DIGITAL_DSE_FISICA', arquivo: "anexo_storage_files_13866707188740534379.pdf_2024-11-27-1732715618757.pdf" }],
            },

            {
                isPaste: true,
                titlePaste: 'Língua Portuguesa',
                arquivos: [
                    { nome: 'VF_DIGITAL_DSE_LINGUA_PORTUGUESA_ARQUIVO_COMPLETO', arquivo: "anexo_storage_files_1315982533541058347.pdf_2024-11-27-1732715684164.pdf" },
                    { nome: 'VF_DIGITAL_DSE_LINGUA_PORTUGUESA_ENSINO-FUNDAMENTAL', arquivo: "anexo_storage_files_16404595966547745578.pdf_2024-11-27-1732715715711.pdf" },
                    { nome: 'VF_DIGITAL_DSE_LINGUA_PORTUGUESA_ENSINO-MEDIO', arquivo: "anexo_storage_files_3526369044793127370.pdf_2024-11-27-1732715735549.pdf" },
                ],
            },
            {
                isPaste: true,
                titlePaste: 'Matemática',
                arquivos: [
                    { nome: 'VF_DIGITAL_DSE_MATEMATICA_ARQUIVO_COMPLETO', arquivo: "anexo_storage_files_11338176317530964083.pdf_2024-11-27-1732715775467.pdf" },
                    { nome: 'VF_DIGITAL_DSE_MATEMATICA_ENSINO-FUNDAMENTAL', arquivo: "anexo_storage_files_4557824860297820042.pdf_2024-11-27-1732715804505.pdf" },
                    { nome: 'VF_DIGITAL_DSE_MATEMATICA_ENSINO-MEDIO', arquivo: "anexo_storage_files_9931435039048629619.pdf_2024-11-27-1732715829667.pdf" },
                ],
            },
            {
                isPaste: true,
                titlePaste: 'Química',
                arquivos: [{ nome: 'VF_DIGITAL_DSE_QUIMICA', arquivo: "anexo_storage_files_10623422311157404166.pdf_2024-11-27-1732715857247.pdf" }],
            },
            {
                isPaste: true,
                titlePaste: 'Sóciologia',
                arquivos: [{ nome: 'VF_DIGITAL_DSE_SOCIOLOGIA_COMPLETO', arquivo: "anexo_storage_files_13736963341400379533.pdf_2024-11-27-1732715893226.pdf" }],
            },
            {
                isPaste: true,
                titlePaste: 'Geográfia Ciências Humanas, Sociais e Aplicadas',
                arquivos: [
                    { nome: 'VF_DIGITAL_DSE_GEOGRAFIA_COMPLETO', arquivo: "anexo_storage_files_13488664409197803372.pdf_2024-11-27-1732715980490.pdf" },
                    { nome: 'VF_DIGITAL_DSE_GEOGRAFIA_ENSINO-FUNDAMENTAL', arquivo: "anexo_storage_files_12126012904956019879.pdf_2024-11-27-1732716031824.pdf" },
                    { nome: 'VF_DIGITAL_DSE_GEOGRAFIA_ENSINO-MEDIO', arquivo: "anexo_storage_files_716510019061998368.pdf_2024-11-27-1732716061015.pdf" },
                ],
            },
            {
                isPaste: true,
                titlePaste: 'História Ciências Humanas, Sociais e Aplicadas',
                arquivos: [
                    { nome: 'VF_DIGITAL_DSE_HISTORIA_COMPLETO', arquivo: "anexo_storage_files_2027238817990709137.pdf_2024-11-27-1732716106973.pdf" },
                    { nome: 'VF_DIGITAL_DSE_HISTORIA_ENSINO-FUNDAMENTAL', arquivo: "anexo_storage_files_1966757641270441451.pdf_2024-11-27-1732716343598.pdf" },
                    { nome: 'VF_DIGITAL_DSE_HISTORIA_ENSINO-MEDIO', arquivo: "anexo_storage_files_17231648406409518423.pdf_2024-11-27-1732716376547.pdf" },
                ],
            },
        ],
    },
    {
        title: 'Checklist do planejamento docente',
        materiais: [
            {
                arquivos: [{ nome: 'checklist_de_apoio_ao_professor', arquivo: "anexo_storage_files_13318458054450248177.pdf_2024-11-27-1732716720784.pdf" }],
            },
        ],
    },
    {
        title: 'Orientações sobre Sistemática de Acompanhamento',
        materiais: [
            {
                arquivos: [{ nome: 'caderno_orientacoes_sobre sistematica_de_acompanhamento', arquivo: "anexo_storage_files_7107164929092659380.pdf_2024-11-27-1732716777599.pdf" }],
            },
        ],
    },
    {
        title: 'Orientações de Gestão e Gerenciamento',
        materiais: [
            {
                arquivos: [{ nome: 'caderno_orientacoes_de_gestao_e_gerenciamento', arquivo: "anexo_storage_files_10528378150225064004.pdf_2024-11-27-1732716821071.pdf" }],
            },
        ],
    },
    {
        title: 'Caderno de Orientação para Avaliação',
        materiais: [
            {
                arquivos: [{ nome: 'caderno_de_orientacao_para_avaliacao_das_competencias_socioemocionais', arquivo: "anexo_storage_files_3644729684912713127.pdf_2024-11-27-1732716870766.pdf" }],
            },
        ],
    },
    {
        title: 'Materiais extras',
        materiais: [
            {
                isPaste: true,
                titlePaste: "Ebooks",
                arquivos: [
                    { nome: 'ebook_competencias_socioemocionais_sao_para_a_vida', arquivo: "anexo_storage_files_713651242561774125.pdf_2024-11-27-1732716933464.pdf" },
                    { nome: 'ebook_trajetorias_socioemocionais_durante_a_pandemia', arquivo: "anexo_storage_files_9790908656776179497.pdf_2024-11-27-1732716960559.pdf" },
                ]
            },
            {
                isPaste: true,
                titlePaste: "Infográficos",
                arquivos: [
                    { nome: 'infografico_bem-estar_x_competencias_socioemocionais', arquivo: "anexo_storage_files_14733477455968691729.pdf_2024-11-27-1732717023104.pdf" },
                    { nome: 'infografico_bullying_x_competencias_socioemocionais', arquivo: "anexo_storage_files_13394448492439089347.pdf_2024-11-27-1732717053253.pdf" },
                    { nome: 'infografico_desempenho_escolar_x_competencias_socioemocionais', arquivo: "anexo_storage_files_97530057755753095.pdf_2024-11-27-1732717093221.pdf" },
                    { nome: 'infografico_pertencimento_x_competencias_socioemocionais', arquivo: "anexo_storage_files_16056961963838349147.pdf_2024-11-27-1732717149552.pdf" },
                    { nome: 'infografico_saude_mental_x_competencias_socioemocionais', arquivo: "anexo_storage_files_8001509282408965629.pdf_2024-11-27-1732717174832.pdf" },
                    { nome: 'infografico_violencia_escolar_x_competencias_socioemocionais', arquivo: "anexo_storage_files_1978266754136740949.pdf_2024-11-27-1732717200631.pdf" },
                ],
            },
        ],
    },
];
