import { jsonProperty, Serializable } from 'ts-serializable';

class ComponentReguaAvaliativaFeedbacks extends Serializable {
    @jsonProperty(Number)
    id: number = -1;

    @jsonProperty(Number)
    nivelMin: number = -1;

    @jsonProperty(Number)
    nivelMax: number = -1;

    @jsonProperty(String)
    text: string = '';
}

export default ComponentReguaAvaliativaFeedbacks;
