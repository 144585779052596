import React, { useState } from 'react';
import styled from 'styled-components';
import Logo from '../assets/dialogos/LogoCiclo.svg';
import Colors from 'enums/Colors';
import { FaArrowRight } from 'react-icons/fa6';
import { useHistory } from 'react-router-dom';
import { IsCustomMaxWidth } from 'core/util/WindowSize';
import { TbLock } from 'react-icons/tb';
import { Popover } from 'antd';
import { MdOutlineOpenInNew } from 'react-icons/md';
import { fetchStorageFile } from 'hooks/StoreFiles';

const Styled = {
    Container: styled.div`
        display: flex;
        height: auto;
    `,

    Content: styled.div`
        display: flex;
        flex-direction: column;
        padding-left: 32px;
        padding-top: 80px;
        max-width: 65%;
        gap: 1rem;
        padding-bottom: 20px;

        @media screen and (max-width: 870px) {
            padding-left: 40px;
            padding-right: 40px;
            width: 100%;
            max-width: 100%;
        }
    `,

    Img: styled.img`
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;

        @media screen and (max-width: 870px) {
            display: none;
        }
    `,

    Title: styled.h1`
        font-family: 'Barlow';
        font-size: 34px;
        font-weight: 700;
        line-height: 51px;
        letter-spacing: -0.019em;
        text-align: left;
        color: ${Colors.Blue};
    `,

    Description: styled.p`
        font-family: 'Barlow';
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.019em;
        text-align: left;
        max-width: 70%;
        margin-bottom: 20px;
        color: ${Colors.Blue};

        @media screen and (max-width: 1250px) {
            width: 80%;
        }

        @media screen and (max-width: 1164px) {
            font-size: 16px;
            max-width: 100%;
        }
    `,

    Item: {
        Container: styled.div`
            display: flex;
            align-items: center;
            justify-content: space-between;
            max-width: 420px;
            height: 60px;
            padding: 10px;
            padding-left: 20px;
            padding-right: 20px;
            border-radius: 4px;
            margin-top: 20px;
            margin-bottom: 20px;
            background-color: ${Colors.Blue};
            color: ${Colors.White};
            cursor: pointer;

            @media screen and (max-width: 500px) {
                width: 100%;
            }
        `,

        Content: styled.div`
            display: flex;
            align-items: center;
            gap: 1rem;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        `,
    },
};

interface IItem {
    title: string;
    url: string;
    isBlock?: boolean;
    titleIsBlock?: string;
}

const Item = ({ title, url, isBlock, titleIsBlock }: IItem) => {
    const history = useHistory();
    const [openPopOver, setPopOver] = useState<boolean>(false);

    return (
        <Popover
            placement="topRight"
            open={openPopOver}
            content={
                <>
                    <p dangerouslySetInnerHTML={{ __html: titleIsBlock ?? '' }} />
                </>
            }
            title={
                <span>
                    Indisponível <TbLock />
                </span>
            }
        >
            <Styled.Item.Container
                onMouseEnter={() => isBlock && setPopOver(true)}
                onMouseLeave={() => isBlock && setPopOver(false)}
                onClick={() => {
                    !isBlock && history.push(url);
                }}
            >
                <p>{title}</p>
                <Styled.Item.Content
                    onClick={() => {
                        !isBlock && history.push(url);
                    }}
                >
                    {isBlock ? (
                        <>
                            Indisponível <TbLock />
                        </>
                    ) : (
                        <>
                            <MdOutlineOpenInNew />
                        </>
                    )}
                </Styled.Item.Content>
            </Styled.Item.Container>
        </Popover>
    );
};

interface ICicloExclusivo {
    title: string;
    description: string;
    itens: IItem[];
    img: string;
}

const CicloExclusivo = ({ title, description, itens, img }: ICicloExclusivo) => {
    const imgStorage = fetchStorageFile(img);

    return (
        <Styled.Container>
            <div
                style={{
                    width: '35%',
                    height: itens.length > 1 ? 'auto' : '500px',
                    padding: '30px',
                    paddingLeft: '70px',
                    display: IsCustomMaxWidth(870) ? 'none' : 'flex',
                }}
            >
                <Styled.Img src={imgStorage} style={{}} />
            </div>
            <Styled.Content>
                <Styled.Title>{`${title}`}</Styled.Title>
                <Styled.Description
                    dangerouslySetInnerHTML={{
                        __html: description,
                    }}
                />
                {itens &&
                    itens.map((dados, index) => (
                        <Item key={index} title={dados.title} url={dados.url} isBlock={dados.isBlock} titleIsBlock={dados.titleIsBlock} />
                    ))}
            </Styled.Content>
        </Styled.Container>
    );
};

export default CicloExclusivo;
