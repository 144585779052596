import { jsonProperty, Serializable } from 'ts-serializable';

class ComponentTimeLine extends Serializable {
    @jsonProperty(Number)
    id: number = -1;

    @jsonProperty(String)
    title: string = '';

    @jsonProperty(String)
    titleEvent: string = '';

    @jsonProperty(String)
    description: string = '';
}

export default ComponentTimeLine;
