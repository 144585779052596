import ComponentTimeLine from 'models/componentInteraction/ComponentTimeLine';
import { GenericService } from '../Generic.service';

export class ComponentTimeLineService extends GenericService {
    readonly URI: string = '/v1/componentTimeLine';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    async insertTimeline(model: ComponentTimeLine, codigoInteraction: number) {
        return await this.post('' + codigoInteraction, model);
    }

    async updateTimeline(model: ComponentTimeLine) {
        return await this.put('', model);
    }

    async deleteTimeline(codigo: number){
        return await this.delete(codigo);
    }
}
