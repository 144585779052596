import { Input } from 'antd';
import { ToastSuccess } from 'components/Toaster';
import { ComponentTimeLineService } from 'core/http/service/componetInteraction/ComponentTimeLine.service';
import Colors from 'enums/Colors';
import useFetch from 'hooks/useFetch';
import ComponentTimeLine from 'models/componentInteraction/ComponentTimeLine';
import editorConfig from 'pages/diarioBordo/components/textEditor/editorConfig';
import React, { useEffect, useState } from 'react';
import { MdAdd, MdDeleteForever, MdSave } from 'react-icons/md';
import ReactQuill from 'react-quill';
import styled from 'styled-components';
import ColorSelected from './subcomponents/ColorSelected';
import { ComponentInteractionService } from 'core/http/service/componetInteraction/ComponentInteraction.service';

const Styled = {
    Container: styled.div`
        display: flex;
        flex-direction: column;
        padding: 76px 0px;
        max-width: 720px;
    `,

    TimeLine: {
        Container: styled.div<{ color: string }>`
            width: 636px;
            padding: 24px 0px 0px 0px;
            gap: 8px;
            border: 0px 0px 0px 2px;
            border-left: 2px solid ${({ color }) => (color ? color : Colors.Blue)};
            background-color: #f5f5f5;
        `,

        Column: styled.div`
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        `,

        Line: styled.div`
            width: 2px;
            height: 80px;
            gap: 0px;
            border: 2px 0px 0px 0px;
            opacity: 0px;
            border: 1px solid #999999;
        `,
    },

    ContentBody: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
    `,

    Button: styled.button`
        width: 38px;
        height: 38px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid ${Colors.Grey11};
        margin-left: 10px;
    `,

    ButtonAdd: styled.button`
        display: flex;
        background-color: ${Colors.Blue};
        width: 60px;
        height: 60px;
        border-radius: 100%;
        align-items: center;
        justify-content: center;
    `,

    Row: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100px;
        gap: 1rem;
    `,
};

interface IComponentTimeLineAdmin {
    dados: ComponentTimeLine[] | null;
    codigoInteraction: number;
    color: string;
}

const ComponentTimeLineAdmin = ({ dados, codigoInteraction, color }: IComponentTimeLineAdmin) => {
    const [timeLines, setTimeLines] = useState<ComponentTimeLine[]>([]);
    const [colorTimeLine, setColorTimeLine] = useState<string>(color);

    //loading
    const [loadingSave, setLoadingSave] = useState<boolean>(false);

    //service
    const service = new ComponentTimeLineService();
    const serviceComponentInteaction = new ComponentInteractionService();

    const handleData = (index: number, value: string) => {
        setTimeLines((prev: any) =>
            prev.map((t: ComponentTimeLine, i: number) => {
                return index === i ? { ...t, title: value } : t;
            })
        );
    };

    const handleTitleEvent = (index: number, value: string) => {
        setTimeLines((prev: any) =>
            prev.map((t: ComponentTimeLine, i: number) => {
                return index === i ? { ...t, titleEvent: value } : t;
            })
        );
    };

    const handleDescription = (index: number, value: string) => {
        setTimeLines((prev: any) =>
            prev.map((t: ComponentTimeLine, i: number) => {
                return index === i ? { ...t, description: value } : t;
            })
        );
    };

    const handleAtualizationTimeLine = (index: number, timeline: ComponentTimeLine) => {
        setTimeLines((prev: any) =>
            prev.map((t: ComponentTimeLine, i: number) => {
                return index === i ? timeline : t;
            })
        );
    };

    const newTimeline = () => {
        setTimeLines([...timeLines, new ComponentTimeLine()]);
    };

    const removeTimeLine = async (index: number, id: number) => {
        if (id !== -1) {
            const { status } = await service.deleteTimeline(id);
            if (status === 204) {
                ToastSuccess('Linha do tempo removida com sucesso!');
            }
        }

        setTimeLines(prev => prev.filter((v: ComponentTimeLine, i: number) => i !== index));
    };

    const handleSave = async (index: number) => {
        setLoadingSave(true);

        try {
            const fetch: ComponentTimeLine = timeLines[index];

            if (fetch.id == -1) {
                const { data, status } = await service.insertTimeline(fetch, codigoInteraction);

                if (status === 201) {
                    ToastSuccess('Linha do tempo salva com sucesso!');
                    handleAtualizationTimeLine(index, data);
                }
            } else {
                const { data, status } = await service.updateTimeline(fetch);

                if (status === 200) {
                    ToastSuccess('Linha do tempo atualizada com sucesso!');
                    handleAtualizationTimeLine(index, data);
                }
            }
        } catch (error) {
            console.log('🚀 ~ handleSave ~ error:', error);
        }
        setLoadingSave(false);
    };

    const updateColor = async (value: string) => {
        const { data, status } = await serviceComponentInteaction.atualizarColor(codigoInteraction, value);

        if (status === 200) {
            setColorTimeLine(data.color);
        }
    };

    useEffect(() => {
        if (dados) {
            setTimeLines(dados);
        }

        if (dados?.length === 0) {
            setTimeLines([new ComponentTimeLine()]);
        }
    }, [dados]);

    return (
        <Styled.Container>
            {timeLines?.map((tl, index) => (
                <Styled.ContentBody key={index}>
                    <Styled.TimeLine.Column>
                        {index > 0 && <Styled.TimeLine.Line />}
                        <Styled.TimeLine.Container color={colorTimeLine}>
                            <Input placeholder="Data" variant="borderless" value={tl.title} onChange={e => handleData(index, e.target.value)} />{' '}
                            <Input
                                value={tl.titleEvent}
                                placeholder="Título"
                                variant="borderless"
                                style={{
                                    fontSize: 22,
                                    fontWeight: 600,
                                    color: colorTimeLine,
                                }}
                                onChange={e => handleTitleEvent(index, e.target.value)}
                                size="large"
                            />{' '}
                            <ReactQuill
                                style={{ width: '100%', height: '160px !important' }}
                                theme="snow"
                                modules={editorConfig.modules}
                                formats={editorConfig.formats}
                                value={tl.description}
                                onChange={val => handleDescription(index, val)}
                            />
                        </Styled.TimeLine.Container>
                    </Styled.TimeLine.Column>
                    <Styled.Button
                        onClick={() => {
                            setLoadingSave(true);
                            handleSave(index);
                        }}
                    >
                        <MdSave size={32} />
                    </Styled.Button>
                    {timeLines.length > 1 && (
                        <Styled.Button onClick={() => removeTimeLine(index, tl.id)}>
                            <MdDeleteForever size={32} color={Colors.DeleteRed} />
                        </Styled.Button>
                    )}
                </Styled.ContentBody>
            ))}

            <Styled.Row style={{ justifyContent: 'space-between', width: '90%' }}>
                <ColorSelected title="Cor da barra" onChangeColor={v => updateColor(v)} color={colorTimeLine} />
                <Styled.ButtonAdd style={{ cursor: 'pointer' }} onClick={newTimeline}>
                    <MdAdd color="white" size={26} />
                </Styled.ButtonAdd>
            </Styled.Row>
        </Styled.Container>
    );
};

export default ComponentTimeLineAdmin;
