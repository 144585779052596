import Colors from 'enums/Colors';
import React, { useEffect, useState } from 'react';
import { MdCheck, MdCheckCircle, MdCheckCircleOutline } from 'react-icons/md';
import styled from 'styled-components';

const Styled = {
    Container: styled.div`
        display: flex;
        gap: 0.5rem;
        flex-direction: column;
    `,

    Text: styled.h2`
        display: flex;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        text-align: left;
    `,

    Row: styled.div`
        display: flex;
        gap: 1rem;
    `,

    Cicler: styled.div<{ color: string }>`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        gap: 0px;
        border-radius: 100%;
        opacity: 0px;
        background-color: ${({ color }) => color && color};
        cursor: pointer;
    `,
};

interface IColorSelected {
    title: string;
    color: string;
    onChangeColor: (value: string) => void;
}

const listColors: string[] = [Colors.Grey10, Colors.GreyBlue, Colors.Blue, Colors.SoftBlue, Colors.Yellow, Colors.Yellow2];

const ColorSelected = ({ title, color, onChangeColor }: IColorSelected) => {
    const [selectedColor, setSelected] = useState<number>(1);

    useEffect(() => {
        const indexColor = listColors.indexOf(color);
        setSelected(indexColor);
    }, [color]);

    return (
        <Styled.Container>
            <Styled.Text>{title}</Styled.Text>
            <Styled.Row>
                {listColors.map((c, i) => (
                    <Styled.Cicler
                        onClick={() => {
                            setSelected(i);
                            onChangeColor(c);
                        }}
                        key={i}
                        color={c}
                    >
                        {i === selectedColor && <MdCheckCircleOutline color="white" size={28} />}
                    </Styled.Cicler>
                ))}
            </Styled.Row>
        </Styled.Container>
    );
};

export default ColorSelected;
