import styled from 'styled-components';

const StyleCircuito = {
    Container: styled.div`
        display: flex;
        flex-direction: column;
        margin-top: 85px;
    `,
};

export default StyleCircuito;
