import React from 'react';
import { FaFileAudio } from 'react-icons/fa6';
import { CiText } from 'react-icons/ci';
import { GrSans } from 'react-icons/gr';
import { MdFormatListNumbered } from 'react-icons/md';

import { ConcluidoNaoConcluidoEnum } from 'enums/ConcluidoNaoConcluidoEnum';
import { ComponentInteractionEnum } from 'enums/ComponentInteractionEnum';
import { RiDragDropFill } from 'react-icons/ri';
import { TfiLayoutSliderAlt } from 'react-icons/tfi';
import { PiTabsDuotone } from 'react-icons/pi';
import { TbHelpSquareFilled } from 'react-icons/tb';
import { FaPencilRuler } from 'react-icons/fa';
import { MdTimeline } from "react-icons/md";

export type TListComponentesInteractions = {
    tipo: ComponentInteractionEnum;
    title: string;
    icon: any;
};

export const ListComponentesInteractions: TListComponentesInteractions[] = [
    {
        tipo: ComponentInteractionEnum.AUDIO,
        title: 'Áudio',
        icon: <FaFileAudio size={22} />,
    },
    {
        tipo: ComponentInteractionEnum.TEXT,
        title: 'Texto',
        icon: <CiText size={22} />,
    },
    {
        tipo: ComponentInteractionEnum.SANFONA,
        title: 'Sanfona',
        icon: <GrSans size={22} />,
    },
    {
        tipo: ComponentInteractionEnum.QUIZ,
        title: 'Quiz',
        icon: <MdFormatListNumbered size={22} />,
    },
    {
        tipo: ComponentInteractionEnum.CORRESPONDENT,
        title: 'Correspondência',
        icon: <RiDragDropFill size={22} />,
    },
    {
        tipo: ComponentInteractionEnum.CARROSSEL,
        title: 'Carrossel',
        icon: <TfiLayoutSliderAlt size={22} />,
    },
    {
        tipo: ComponentInteractionEnum.ABAS,
        title: 'Abas',
        icon: <PiTabsDuotone size={22} />,
    },
    {
        tipo: ComponentInteractionEnum.CARD_BARALHO,
        title: 'Baralho',
        icon: <TbHelpSquareFilled size={22} />,
    },
    {
        tipo: ComponentInteractionEnum.REGUA_AVALIATIVA,
        title: 'Régua Avaliativa',
        icon: <FaPencilRuler size={22} />,
    },
    {
        tipo: ComponentInteractionEnum.TIMELINE,
        title: 'Linha do tempo',
        icon: <MdTimeline size={22} />,
    },
];
