import { Button, Input, InputNumber, Select, Slider, SliderSingleProps } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { ToastError, ToastSuccess } from 'components/Toaster';
import { ComponentReguaAvaliativaService } from 'core/http/service/componetInteraction/ComponentReguaAvaliativa.service';
import { ComponentReguaAvaliativaFeedbackService } from 'core/http/service/componetInteraction/ComponentReguaAvaliativaFeedback.service';
import { ComponentReguaAvaliativaQquestionsService } from 'core/http/service/componetInteraction/ComponentReguaAvaliativaQuestions.service';
import Colors from 'enums/Colors';
import ComponentReguaAvaliativaModel from 'models/componentInteraction/ComponenteReguaAvaliativa';
import ComponentReguaAvaliativaFeedbacks from 'models/componentInteraction/ComponentReguaAvaliativaFeedbacks';
import ComponentReguaAvaliativaOptions from 'models/componentInteraction/ComponentReguaAvaliativaOptions';
import ComponentReguaAvaliativaQuestions from 'models/componentInteraction/ComponentReguaAvaliativaQuestions';
import editorConfig from 'pages/diarioBordo/components/textEditor/editorConfig';
import React, { useEffect, useState } from 'react';
import { FaRegEye } from 'react-icons/fa';
import { FiCheckCircle } from 'react-icons/fi';
import { MdDeleteForever, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdSaveAs } from 'react-icons/md';
import ReactQuill from 'react-quill';
import styled from 'styled-components';

const Styled = {
    Container: styled.div`
        display: flex;
        flex-direction: column;
        max-width: 1280px;
        gap: 1rem;
    `,

    Row: styled.div`
        display: flex;
    `,

    RowEnd: styled.div`
        display: flex;
        align-items: center;
        justify-content: end;
        width: 100%;
        gap: 1rem;
    `,

    RowBetween: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
    `,

    Title: styled.h1`
        font-family: 'Barlow';
        font-size: 18px;
        font-weight: 600;
        line-height: 25.2px;
        text-align: left;
        text-decoration-skip-ink: none;
        color: ${Colors.Blue};
    `,

    SubTitle: styled.h2`
        font-family: 'Barlow';
        font-size: 14px;
        font-weight: 600;
        line-height: 19.6px;
        text-align: left;
        text-decoration-skip-ink: none;
    `,

    Preview: styled.div<{ isPreview: boolean }>`
        border: 1px solid ${Colors.Blue};
        width: 60px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        background-color: ${({ isPreview }) => (isPreview ? Colors.Blue : Colors.White)};
        cursor: pointer;
    `,

    Column: styled.div`
        display: flex;
        width: 100%;
        gap: 0.5rem;
        flex-direction: column;
    `,

    Regua: {
        Container: styled.div``,
    },

    Options: {
        Container: styled.div`
            display: flex;
            align-items: center;
            gap: 1rem;
            height: 52px;
        `,

        NumberPosition: styled.div`
            width: 50px;
            height: 50px;
            padding: 15px 21px 15px 21px;
            gap: 16px;
            border-radius: 5px;
            border: 1px 0px 0px 0px;
            opacity: 0px;
            border: 1px solid #e6e6e6;
            background: #f9f9f9;
        `,
    },

    Navigation: {
        Arrow: styled.div`
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 10px;
            margin-right: 10px;
            width: 62px;
            border-radius: 5px;
            border: 1px solid #e6e6e6;
            cursor: pointer;
            transition: all 0.3s;

            &:hover {
                border: 1px solid ${Colors.Blue};
            }
        `,

        ItemFooter: styled.div<{ isActive: boolean }>`
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            border-radius: 4px;
            align-items: center;
            justify-content: center;
            border: 1px solid ${Colors.Blue};
            color: ${({ isActive }) => (isActive ? Colors.White : Colors.Blue)};
            background-color: ${({ isActive }) => (isActive ? Colors.Blue : Colors.White)};
            cursor: pointer;
        `,
    },

    Feedback: {
        Container: styled.div`
            display: flex;
            gap: 1rem;
            height: 133px;
            width: 100%;
        `,
    },
};

interface IComponentReguaAvaliativaAdmin {
    dados: ComponentReguaAvaliativaModel | null;
    codigoInteraction: number;
}

interface IComponent {
    niveis: ComponentReguaAvaliativaOptions[];
}
interface INiveis {
    nivel: ComponentReguaAvaliativaOptions;
    removeOptionIndex: () => void;
    handleText: (value: string) => void;
    isEdit: boolean;
    codigoQuestions: number;
    lastOption: boolean;
}

interface IFeedback {
    dados: ComponentReguaAvaliativaFeedbacks;
    handleValue: (isText: boolean, isMinNivel: boolean, value: string | number) => void;
    removeIndex: () => void;
    length: number;
    isEdit: boolean;
    handleSaveFeed: () => void;
}

const Feedback = ({ dados, handleValue, removeIndex, length, isEdit, handleSaveFeed }: IFeedback) => {
    const [isModify, setIsModify] = useState<boolean>(false);

    return (
        <Styled.Feedback.Container>
            <InputNumber
                placeholder="N"
                style={{ height: 62, width: 82, textAlign: 'center', fontSize: 32 }}
                maxLength={1}
                value={dados.nivelMin}
                min={1}
                onChange={v => {
                    handleValue(false, true, v ?? -1);
                    setIsModify(true);
                }}
            />
            <InputNumber
                placeholder="N"
                style={{ height: 62, width: 82, textAlign: 'center', fontSize: 32 }}
                maxLength={1}
                value={dados.nivelMax}
                min={1}
                onChange={v => {
                    handleValue(false, false, v ?? -1);
                    setIsModify(true);
                }}
            />
            <TextArea
                size="large"
                style={{ marginBottom: 22 }}
                showCount
                maxLength={200}
                rows={3}
                value={dados.text}
                onChange={e => {
                    handleValue(true, false, e.target.value);
                    setIsModify(true);
                }}
                placeholder="Texto do feedback"
            />
            {isEdit && isModify && (
                <MdSaveAs
                    size={24}
                    color={Colors.Blue}
                    cursor={'pointer'}
                    onClick={() => {
                        handleSaveFeed();
                        setIsModify(false);
                    }}
                />
            )}
            {length > 1 && <MdDeleteForever size={24} color={Colors.DeleteRed} cursor={'pointer'} onClick={removeIndex} />}
        </Styled.Feedback.Container>
    );
};

const Options = ({ nivel, removeOptionIndex, handleText, isEdit, codigoQuestions, lastOption }: INiveis) => {
    const [modifyText, setModifyText] = useState<boolean>(false);

    //service
    const service = new ComponentReguaAvaliativaQquestionsService();

    const handleSaveQuestions = async () => {
        const { status } = await service.atualizarOption(nivel, codigoQuestions);

        if (status === 200) {
            ToastSuccess('Opção salva com sucesso!');
            setModifyText(false);
        }
    };

    return (
        <Styled.Options.Container>
            <Styled.Options.NumberPosition>{nivel.nivel}</Styled.Options.NumberPosition>
            <Input
                size="large"
                style={{ height: '100%', width: '1000px' }}
                placeholder="Escreva a descrição do item aqui"
                showCount
                maxLength={100}
                value={nivel.text}
                onChange={e => {
                    handleText(e.target.value);
                    isEdit && setModifyText(true);
                }}
            />
            {isEdit && modifyText && <MdSaveAs size={24} color={Colors.Blue} cursor={'pointer'} onClick={handleSaveQuestions} />}
            {lastOption && <MdDeleteForever size={24} color={Colors.DeleteRed} cursor={'pointer'} onClick={removeOptionIndex} />}
        </Styled.Options.Container>
    );
};

const Regua = ({ niveis }: IComponent) => {
    const niveisComponent = niveis.reduce((acc: any, d, index) => {
        acc[index] = d.text ?? ' ';
        return acc;
    }, {});

    return (
        <div style={{ height: '100px', width: '100%' }}>
            <Slider autoFocus marks={niveisComponent} max={niveis.length - 1} step={null} defaultValue={0} />
        </div>
    );
};

const ComponentReguaAvaliativaAdmin = ({ dados, codigoInteraction }: IComponentReguaAvaliativaAdmin) => {
    const [preview, setPreview] = useState<boolean>(false);
    const [regua, setRegua] = useState<ComponentReguaAvaliativaModel | null>(dados);
    console.log("🚀 ~ ComponentReguaAvaliativaAdmin ~ regua:", regua)
    const [indexQuestion, setIndexQuestion] = useState<number>(0);
    const [quantityRegua, setQuantityRegua] = useState<number>(1);
    const [isEdit, setIsEdit] = useState<boolean>(false);

    //loadings
    const [loadingRemoveQuestion, setLoadingRemoveQuestion] = useState<boolean>(false);

    //QuestionsNotSave
    const [numberIndexQuestionNotSave, setNumberIndexQuestionNotSave] = useState<number[]>([]);

    const [textQuestionAtt, setTextQuetionsAtt] = useState<boolean>(false);
    const [textRegua, setTextRegua] = useState<boolean>(false);

    //services
    const serviceReguaAvaliativa = new ComponentReguaAvaliativaService();
    const serviceQuestions = new ComponentReguaAvaliativaQquestionsService();
    const serviceFeedback = new ComponentReguaAvaliativaFeedbackService();

    const structureQuestions = () => {
        const questions = new ComponentReguaAvaliativaQuestions().fromJSON({
            titulo: '',
            textAux: '',
        });

        const niveis = [
            ComponentReguaAvaliativaOptions.fromJSON({ nivel: 1, titulo: '' }),
            ComponentReguaAvaliativaOptions.fromJSON({ nivel: 2, titulo: '' }),
            ComponentReguaAvaliativaOptions.fromJSON({ nivel: 3, titulo: '' }),
            ComponentReguaAvaliativaOptions.fromJSON({ nivel: 4, titulo: '' }),
            ComponentReguaAvaliativaOptions.fromJSON({ nivel: 5, titulo: '' }),
        ];

        questions.options = [...(questions.options || []), ...niveis];

        return questions;
    };

    const handleNewQuestions = () => {
        const questions = structureQuestions();

        setRegua((prev: any) => ({
            ...prev,
            questions: [...(prev.questions || []), questions],
        }));

        setQuantityRegua(quantityRegua + 1);
        setIndexQuestion(quantityRegua);

        setNumberIndexQuestionNotSave([...numberIndexQuestionNotSave, quantityRegua]);
        setTextQuetionsAtt(false);
    };

    const isPossibilytEdit = (): boolean => {
        if (isEdit) {
            if (numberIndexQuestionNotSave.length > 0 ? numberIndexQuestionNotSave.some((n: number) => n !== indexQuestion) : true) {
                return true;
            }
        }

        return false;
    };

    const validationInputAll = () => {
        if (!regua) return false;

        // Verificar campos principais de regua
        if (!regua.titulo || !regua.textTituloAux) {
            return false;
        }

        // Verificar questões e opções
        // const invalidQuestion = regua.questions.some(question => question.options?.some(option => option.text.length === 0));
        // if (invalidQuestion) {
        //     return false;
        // }

        // Verificar feedbacks
        const invalidFeedback = regua.feedbacks.some(feedback => !feedback.text);
        if (invalidFeedback) {
            return false;
        }

        return true; // Tudo válido
    };

    // const validationInputQuestion = (): boolean => {
    //     if (regua) {
    //         const invalidQuestion = regua.questions.some(
    //             (question: ComponentReguaAvaliativaQuestions, index: number) =>
    //                 index === indexQuestion && question.options?.some(option => option.text.length === 0)
    //         );

    //         if (invalidQuestion) {
    //             return false;
    //         }

    //         return true;
    //     }

    //     return false;
    // };

    const handleFeedback = (index: number, isText: boolean, isMinNivel: boolean, value: string | number) => {
        setRegua((prev: any) => {
            if (!prev.feedbacks || index < 0 || index >= prev.feedbacks.length) {
                return prev; // Retorna o estado anterior se o índice for inválido
            }

            return {
                ...prev,
                feedbacks: prev.feedbacks.map(
                    (feedback: ComponentReguaAvaliativaFeedbacks, i: number) =>
                        i === index
                            ? {
                                  ...feedback,
                                  text: isText ? value : feedback.text,
                                  nivelMin: isMinNivel ? value : feedback.nivelMin,
                                  nivelMax: !isMinNivel && !isText ? value : feedback.nivelMax,
                              }
                            : feedback // Atualiza o feedback específico
                ),
            };
        });
    };

    const handleNewFeedback = () => {
        setRegua((prev: any) => {
            const minMinimo = prev.feedbacks.at(-1).nivelMax;

            const newFeed = new ComponentReguaAvaliativaFeedbacks().fromJSON({
                nivelMin: minMinimo + 1,
                nivelMax: minMinimo + 2,
            });

            return {
                ...prev,
                feedbacks: [...(prev.feedbacks || []), newFeed],
            };
        });
    };

    const removeFeedback = async (index: number, id: number) => {
        if (isEdit) {
            const { status } = await serviceFeedback.delete(id);

            if (status == 204) {
                ToastSuccess('Feedback removido com sucesso!');
            }
        }

        setRegua((prev: any) => ({
            ...prev,
            feedbacks: prev.feedbacks.filter((_: ComponentReguaAvaliativaFeedbacks, i: number) => i !== index),
        }));
    };

    const handleTextRegua = (text: string, isTitle: boolean) => {
        setRegua((prev: any) => {
            if (isTitle) {
                return {
                    ...prev,
                    titulo: text,
                };
            } else {
                return {
                    ...prev,
                    textTituloAux: text,
                };
            }
        });

        if (isPossibilytEdit()) {
            setTextRegua(true);
        }
    };
    const handleTextQuestion = (text: string, isTitle: boolean) => {
        setRegua((prev: any) => {
            if (isTitle) {
                // Atualiza o título da pergunta correspondente ao índice indexQuestion
                return {
                    ...prev,
                    questions: prev.questions.map((q: ComponentReguaAvaliativaQuestions, i: number) =>
                        i === indexQuestion ? { ...q, titulo: text } : q
                    ),
                };
            } else {
                // Atualiza o texto auxiliar do título
                return {
                    ...prev,
                    questions: prev.questions.map((q: ComponentReguaAvaliativaQuestions, i: number) =>
                        i === indexQuestion ? { ...q, textAux: text } : q
                    ),
                };
            }
        });

        if (isPossibilytEdit()) {
            setTextQuetionsAtt(true);
        }
    };
    const adicionarNivel = () => {
        const lenghtOptions = regua?.questions[indexQuestion].options?.length ?? 0;

        if (lenghtOptions > 0) {
            const nivel = regua?.questions[indexQuestion]?.options?.at(-1)?.nivel;

            setRegua((prev: any) => {
                // Verifica se prev e suas propriedades são válidas
                if (!prev || !prev.questions || !prev.questions[indexQuestion]) {
                    return prev; // Retorna o estado anterior se não for válido
                }

                // Cria a nova opção
                const novaOpcao = new ComponentReguaAvaliativaOptions().fromJSON({
                    nivel: (nivel ?? 0) + 1, // Corrigido o operador de adição
                    titulo: '',
                });

                // Atualiza o estado, mantendo as opções anteriores e adicionando a nova opção
                const updatedOptions = [...prev.questions[indexQuestion].options, novaOpcao];

                // Retorna o novo estado
                return {
                    ...prev,
                    questions: prev.questions.map((q: ComponentReguaAvaliativaQuestions, i: number) =>
                        i === indexQuestion ? { ...q, options: updatedOptions } : q
                    ),
                };
            });
        }
    };

    const removerNivel = async (nivelARemover: number, codigo: number) => {
        if (isEdit) {
            const { status } = await serviceQuestions.deleteOption(codigo);

            if (status === 204) {
                ToastSuccess('Opção removida com sucesso!');
            }
        }

        setRegua((prev: any) => {
            // Verifica se prev e suas propriedades são válidas
            if (!prev || !prev.questions || !prev.questions[indexQuestion]) {
                return prev; // Retorna o estado anterior se não for válido
            }

            // Filtra as opções para remover a que possui o nivelARemover
            const updatedOptions = prev.questions[indexQuestion].options.filter(
                (option: ComponentReguaAvaliativaOptions) => option.nivel !== nivelARemover
            );

            // Retorna o novo estado, mantendo as opções atualizadas
            return {
                ...prev,
                questions: prev.questions.map((q: ComponentReguaAvaliativaQuestions, i: number) =>
                    i === indexQuestion ? { ...q, options: updatedOptions } : q
                ),
            };
        });
    };

    const handleTextOption = (nivel: number, text: string) => {
        let options = regua?.questions[indexQuestion].options;
        let newOptions = options?.map((o, i) => (o.nivel === nivel ? { ...o, text: text } : o));

        setRegua((prevRegua: any) => {
            // Retorne um novo objeto que contém o estado anterior, mas com as opções atualizadas
            return {
                ...prevRegua,
                questions: prevRegua.questions.map((q: ComponentReguaAvaliativaQuestions, i: number) =>
                    i === indexQuestion ? { ...q, options: newOptions } : q
                ),
            };
        });
    };

    const nextRegua = () => {
        if (quantityRegua > 1 && indexQuestion + 1 < quantityRegua) {
            setIndexQuestion(indexQuestion + 1);
            return;
        }

        if (indexQuestion + 1 === quantityRegua) {
            setIndexQuestion(99);
        }
    };

    const reviver = () => {
        if (indexQuestion === 99) {
            setIndexQuestion(quantityRegua - 1);
            return;
        }

        if (indexQuestion > 0) {
            setIndexQuestion(indexQuestion - 1);
        }
    };

    const handleSave = async () => {
        if (!validationInputAll()) {
            return ToastError('O formulário possui campos obrigatórios vazios');
        }

        if (regua) {
            const { data, status } = await serviceReguaAvaliativa.insert(regua, codigoInteraction);

            if (status === 201) {
                ToastSuccess('Régua avaliativa salva com sucesso!');
                setRegua(data);
                setIsEdit(true)
                setNumberIndexQuestionNotSave([])
            }
        }
    };

    const handleUpdateRegua = async () => {
        if (regua) {
            const { data, status } = await serviceReguaAvaliativa.updateRegua(regua, codigoInteraction);

            if (status === 200) {
                ToastSuccess('Régua avaliativa atualizada com sucesso!');
                setRegua((prev: any) => {
                    return {
                        ...prev,
                        id: data.id,
                        titulo: data.titulo,
                        textTituloAux: data.textTituloAux,
                    };
                });
            }
        }
    };

    const handleUpdateInfoQuestions = async () => {
        if (regua?.questions[indexQuestion]) {
            const { status } = await serviceQuestions.atualizarInformationQuestion(regua?.questions[indexQuestion], regua?.id);

            if (status == 200) {
                ToastSuccess('Informações da questão atualizada com sucesso!');
            }
        }
    };

    const handleSaveNewQuestions = async () => {
        // if (!validationInputQuestion()) {
        //     return ToastError('O formulário possui campos obrigatórios vazios');
        // }

        if (regua?.questions[indexQuestion]) {
            const { data, status } = await serviceQuestions.insert(regua?.questions[indexQuestion], regua?.id);

            if (status == 201) {
                ToastSuccess('Nova régua adicionada!');

                const numberRemove = numberIndexQuestionNotSave.filter(n => n != indexQuestion);
                setNumberIndexQuestionNotSave(numberRemove);

                setRegua((prev: any) => {
                    return {
                        ...prev,
                        questions: prev.questions.map((q: ComponentReguaAvaliativaQuestions, i: number) =>
                            i === indexQuestion ? { ...q, id: data.id, titulo: data.titulo, textAux: data.textAux, options: data.options } : q
                        ),
                    };
                });
            }
        }
    };

    const handleInsertAndUpdateFeedback = async (index: number) => {
        if (regua?.feedbacks[index]) {
            const { data, status } = await serviceFeedback.insertAndUpdate(regua?.feedbacks[index], regua?.id);

            if (status == 200) {
                ToastSuccess('Feedback salvo com sucesso!');

                setRegua((prev: any) => {
                    return {
                        ...prev,
                        feedbacks: prev.feedbacks.map((f: ComponentReguaAvaliativaFeedbacks, i: number) => (i === index ? data : f)),
                    };
                });
            }
        }
    };

    const removeRegua = async () => {
        setLoadingRemoveQuestion(true);
        if (regua?.questions[indexQuestion]) {
            const id = regua?.questions[indexQuestion].id;

            const { status } = await serviceQuestions.deleteQuestion(id);

            if (status === 204) {
                ToastSuccess('Régua removida com sucesso!');

                setIndexQuestion(indexQuestion - 1);
                setQuantityRegua(quantityRegua - 1);

                setRegua((prev: any) => {
                    const newList = prev.questions.filter((q: any) => q.id !== id);

                    return {
                        ...prev,
                        questions: newList,
                    };
                });
            }
        }
        setLoadingRemoveQuestion(false);
    };

    useEffect(() => {
        const questions = structureQuestions();
        const feedback = new ComponentReguaAvaliativaFeedbacks().fromJSON({
            nivelMin: 1,
            nivelMax: 2,
        });

        if (dados === null) {
            const rv = new ComponentReguaAvaliativaModel();

            rv.questions.push(questions);
            rv.feedbacks.push(feedback);

            setRegua(rv);
        }

        if (dados) {
            if (dados.id != -1) {
                setIsEdit(true);
            }

            if (dados.questions.length === 0) {
                dados.questions.push(questions);
            }

            if (dados.feedbacks.length === 0) {
                dados.feedbacks.push(feedback);
            }

            setQuantityRegua(dados.questions.length);
        }
    }, [dados]);

    useEffect(() => {
        setTextQuetionsAtt(false);
        setTextRegua(false);
    }, [indexQuestion]);

    useEffect(() => {
        console.log('🚀 ~ ComponentReguaAvaliativaAdmin ~ regua:', regua);
    }, [regua]);

    return (
        <Styled.Container>
            <Styled.RowBetween>
                <Styled.Title>Régua avaliativa</Styled.Title>
            </Styled.RowBetween>
            <Input
                size="large"
                placeholder="Digite o título"
                showCount
                maxLength={200}
                value={regua?.titulo}
                onChange={e => handleTextRegua(e.target.value, true)}
            />
            <ReactQuill
                style={{ marginBottom: '20px' }}
                theme="snow"
                modules={editorConfig.modules2}
                formats={editorConfig.formats2}
                value={regua?.textTituloAux}
                onChange={val => {
                    handleTextRegua(val, false);
                }}
            />

            {isEdit && textRegua && (
                <Button size="large" style={{ width: 200, position: 'relative' }} onClick={handleUpdateRegua}>
                    <MdSaveAs /> Salvar informações
                </Button>
            )}

            <Styled.RowBetween style={{ justifyContent: 'end', width: '100%' }}></Styled.RowBetween>
            {indexQuestion !== 99 && regua?.questions[indexQuestion] && (
                <>
                    <Styled.Row>
                        {regua?.questions?.[indexQuestion]?.options && <Regua niveis={regua?.questions[indexQuestion].options ?? []} />}
                    </Styled.Row>
                    <Styled.Row>
                        {indexQuestion !== 0 && (
                            <Styled.Navigation.Arrow onClick={reviver}>
                                <MdKeyboardArrowLeft size={22} />
                            </Styled.Navigation.Arrow>
                        )}
                        <Styled.Column>
                            <Input
                                size="large"
                                placeholder="Digite o subtítulo"
                                showCount
                                maxLength={200}
                                value={regua?.questions[indexQuestion].titulo ?? ''}
                                onChange={e => {
                                    handleTextQuestion(e.target.value, true);
                                }}
                            />
                            <TextArea
                                size="large"
                                style={{ marginBottom: 22 }}
                                showCount
                                maxLength={200}
                                rows={4}
                                value={regua?.questions[indexQuestion].textAux ?? ''}
                                onChange={e => {
                                    handleTextQuestion(e.target.value, false);
                                }}
                                placeholder="Texto para auxilio"
                            />
                            {isEdit && textQuestionAtt && (
                                <Button
                                    size="large"
                                    style={{ width: 200, position: 'relative', bottom: 22 }}
                                    onClick={handleUpdateInfoQuestions}
                                >
                                    <MdSaveAs /> Salvar informações
                                </Button>
                            )}

                            {regua?.questions?.[indexQuestion]?.options?.map((n: any, index: number) => (
                                <Options
                                    key={index}
                                    nivel={n}
                                    removeOptionIndex={() => removerNivel(n.nivel, n.id)}
                                    handleText={v => handleTextOption(n.nivel, v)}
                                    isEdit={
                                        (numberIndexQuestionNotSave.length > 0
                                            ? numberIndexQuestionNotSave.some((n: number) => n !== indexQuestion)
                                            : true) && isEdit
                                    }
                                    codigoQuestions={isEdit ? regua.questions[indexQuestion].id : -1}
                                    lastOption={index + 1 === regua?.questions?.[indexQuestion]?.options?.length}
                                />
                            ))}
                        </Styled.Column>
                        <Styled.Navigation.Arrow onClick={nextRegua}>
                            <MdKeyboardArrowRight size={22} />
                        </Styled.Navigation.Arrow>
                    </Styled.Row>
                    <Styled.RowBetween>
                        <Styled.Column style={{ width: 'auto' }}>
                            <Styled.SubTitle>Adicionar mais um nível</Styled.SubTitle>
                            <Button size="large" style={{ width: 200 }} onClick={adicionarNivel}>
                                Adicionar + 1
                            </Button>
                        </Styled.Column>
                        {isPossibilytEdit() && regua?.questions && regua?.questions.length > 1 && (
                            <Button
                                size="large"
                                type="text"
                                loading={loadingRemoveQuestion}
                                style={{ width: 200, border: 'dashed 1px' + Colors.DeleteRed, color: Colors.DeleteRed }}
                                onClick={removeRegua}
                            >
                                <MdDeleteForever /> Remover régua
                            </Button>
                        )}

                        {numberIndexQuestionNotSave.some((n: number) => n === indexQuestion) && regua && regua.id !== -1 && (
                            <Button size="large" style={{ width: 200 }} onClick={handleSaveNewQuestions}>
                                <MdSaveAs /> Salvar régua
                            </Button>
                        )}

                        <Styled.Column style={{ width: 'auto' }}>
                            <Styled.SubTitle>Adicionar mais uma régua</Styled.SubTitle>
                            <Button size="large" style={{ width: 200 }} onClick={handleNewQuestions}>
                                Adicionar + 1
                            </Button>
                        </Styled.Column>
                    </Styled.RowBetween>
                </>
            )}
            {indexQuestion === 99 && (
                <>
                    <Styled.Row>
                        <Styled.Navigation.Arrow onClick={reviver}>
                            <MdKeyboardArrowLeft size={22} />
                        </Styled.Navigation.Arrow>
                        <Styled.Column>
                            <Styled.Title>Feedbacks</Styled.Title>
                            {regua?.feedbacks.map((f, i) => (
                                <Feedback
                                    key={i}
                                    dados={f}
                                    handleValue={(isText, isMinNivel, v) => handleFeedback(i, isText, isMinNivel, v)}
                                    removeIndex={() => removeFeedback(i, f.id)}
                                    length={regua.feedbacks.length}
                                    isEdit={isEdit}
                                    handleSaveFeed={() => handleInsertAndUpdateFeedback(i)}
                                />
                            ))}
                            <Styled.Column style={{ width: 'auto' }}>
                                <Styled.SubTitle>Adicionar mais um feedback</Styled.SubTitle>
                                <Styled.RowBetween>
                                    <Button size="large" style={{ width: 200 }} onClick={handleNewFeedback}>
                                        Adicionar + 1
                                    </Button>
                                    {!isEdit && (
                                        <Button size="large" style={{ width: 200 }} onClick={handleSave}>
                                            <MdSaveAs /> Salvar régua
                                        </Button>
                                    )}
                                </Styled.RowBetween>
                            </Styled.Column>
                        </Styled.Column>
                    </Styled.Row>
                </>
            )}
            <Styled.RowEnd>
                <p>Navegue entre as réguas:</p>
                {Array.from({ length: quantityRegua }).map((_, index) => (
                    <Styled.Navigation.ItemFooter onClick={() => setIndexQuestion(index)} isActive={indexQuestion == index} key={index}>
                        {index + 1}
                    </Styled.Navigation.ItemFooter>
                ))}

                <Styled.Navigation.ItemFooter onClick={() => setIndexQuestion(99)} isActive={indexQuestion === 99}>
                    <FiCheckCircle color={indexQuestion === 99 ? Colors.White : Colors.Blue} />
                </Styled.Navigation.ItemFooter>
            </Styled.RowEnd>
        </Styled.Container>
    );
};

export default ComponentReguaAvaliativaAdmin;
