import { jsonProperty, Serializable } from 'ts-serializable';

class ComponentReguaAvaliativaOptions extends Serializable {
    @jsonProperty(Number)
    id: number = -1;

    @jsonProperty(Number)
    nivel: number = -1;

    @jsonProperty(String)
    text: string = '';
}

export default ComponentReguaAvaliativaOptions;
