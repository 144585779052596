import ComponentReguaAvaliativaModel from 'models/componentInteraction/ComponenteReguaAvaliativa';
import { GenericService } from '../Generic.service';

export class ComponentReguaAvaliativaService extends GenericService {
    readonly URI: string = '/v1/componentReguaAvaliativa';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    async insert(model: ComponentReguaAvaliativaModel, codigoInteraction: number) {
        return await this.post('' + codigoInteraction, model);
    }

    async updateRegua(model: ComponentReguaAvaliativaModel, codigoInteraction: number){
        return await this.put('' + codigoInteraction, model);
    }
}
