import ComponentInteractionModel from 'models/componentInteraction/ComponentInteraction';
import { GenericService } from '../Generic.service';

export class ComponentInteractionService extends GenericService {
    readonly URI: string = '/v1/componentInteraction';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public atualizarAndSave(idContedo: number, data: ComponentInteractionModel) {
        return this.put('save/' + idContedo, data);
    }

    public atualizarAllOrdens(idContedo: number, data: ComponentInteractionModel[]) {
        return this.put('atualizarOrdens/' + idContedo, data);
    }

    public deleteById(idComponente: number) {
        return this.delete(idComponente);
    }

    public atualizarQuantity(idComponent: number, quantity: Number) {
        return this.put('atualizarQuantity/' + idComponent, { quantity });
    }
    
    public atualizarColor(idComponent: number, color: string) {
        return this.put('color/' + idComponent, { color });
    }
}
